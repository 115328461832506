<script lang="ts" setup>
import { computed, onMounted } from 'vue';
import { useHandleDataSearchComponent } from '../hooks/useHandleDataSearchComponent';
import type { ProductSource } from '../type';
import { PRODUCT_SOURCE_TYPE } from '../../../product-list/constant';
import { STEP_PICKER } from '../constant';
import { InputText } from '@gem/element-setting-ui';

const props = defineProps<{
  productSrcType?: ProductSource;
  stepPicker?: STEP_PICKER;
}>();
const emit = defineEmits<{
  (e: 'onSearch', value?: string): void;
}>();

const {
  onBlurInputSearch,
  openSearchSetting,
  closeSearchSetting,
  onChangeSearch,
  searchKeyword,
  inputSearch,
  isSearchSetting,
} = useHandleDataSearchComponent(emit);

defineExpose({
  closeSearchSetting,
  openSearchSetting,
  searchKeyword,
});

const isCollectionType = computed(
  () => props.productSrcType === 'Collection' || props.productSrcType === 'DynamicCollection',
);

const placeholderInputSearch = computed(() => {
  return isCollectionType.value ? 'Search collection' : 'Search product';
});

onMounted(() => {
  if (
    props.productSrcType === PRODUCT_SOURCE_TYPE.PICK_PRODUCT &&
    props.stepPicker === STEP_PICKER.PICK_PRODUCT &&
    inputSearch.value
  ) {
    openSearchSetting();
  }
});
</script>

<template>
  <div
    v-if="productSrcType !== 'RelatedProduct'"
    :class="{ 'w-full': isSearchSetting }"
    class="px-16"
    data-test="editor-control-search-setting">
    <div class="w-full items-center">
      <InputText
        ref="inputSearch"
        type="text"
        input-style="secondary"
        :placeholder="t(placeholderInputSearch)"
        :value="searchKeyword"
        prefix-icon="polaris-search"
        @blur="onBlurInputSearch"
        @on-change="onChangeSearch" />
    </div>
  </div>
</template>
