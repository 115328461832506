<script lang="ts" setup>
import { computed } from 'vue';
import type { GroupTypeSetting, ObjectDevices } from '../types';
import type { ScreenType } from '../types';
import Toggler from './Toggler.vue';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

type PropsType = {
  id?: string;
  value?: boolean;
  devices?: ObjectDevices<{ default?: boolean }>;
};
type ActionSettingInput = {
  groupType: GroupTypeSetting;
  componentUid: string;
  controlType: string;
  controlId: string;
  newValue: any;
  screenId: ScreenType;
  hasDevices?: boolean;
};

const props = defineProps<PropsType>();

const devicesValue = computed((): ObjectDevices<{ default?: boolean }> | undefined => {
  return {
    desktop: checkToggleAbility({ ...props.devices, desktop: { default: props.devices?.desktop?.default } })
      ? { default: props.devices?.desktop?.default }
      : { default: true },
    tablet: checkToggleAbility({
      ...props.devices,
      tablet: { default: props.devices?.tablet?.default },
    })
      ? { default: props.devices?.tablet?.default }
      : { default: true },
    mobile: checkToggleAbility({
      ...props.devices,
      mobile: {
        default: props.devices?.mobile?.default,
      },
    })
      ? { default: props.devices?.mobile?.default }
      : { default: true },
  };
});

// const devicesDisplay = computed(() => {
//   const devices = Object.keys(props.devices ?? {})
//     .filter((key) => {
//       return Boolean(props.devices?.[key as ScreenType]?.default);
//     })
//     .map((key) => {
//       return key.charAt(0).toUpperCase() + key.slice(1);
//     })
//     .join(', ');
//   if (devices === 'Desktop, Tablet, Mobile') return '(All)';
//   return `(${devices})`;
// });

const emit = defineEmits<{
  (e: 'controlChange', controlId?: string, value?: boolean, devices?: ScreenType): void;
  (e: 'controlChangeValue', input: Pick<ActionSettingInput, 'screenId' | 'newValue'>[]): void;
}>();

const checkToggleAbility = (checkObject?: ObjectDevices<{ default?: boolean }>) => {
  if (checkObject === undefined) return;
  return Object.values(checkObject).filter((v) => v.default === true && v.default !== undefined).length >= 1;
};

const change = (id?: string, toggleV?: boolean) => {
  if (devicesValue.value) {
    if (toggleV === false && !checkToggleAbility({ ...devicesValue.value, [id as ScreenType]: { default: false } })) {
      alert("You can't hide this element in all screens, better to delete it.");
      return;
    }
    const clone = { ...devicesValue.value, [id as ScreenType]: { default: toggleV } };

    const input = Object.keys(clone).map((key) => {
      if (key !== id)
        return {
          screenId: key as ScreenType,
          newValue: clone[key as ScreenType]?.default,
        };
      return {
        screenId: id as ScreenType,
        newValue: toggleV,
      };
    });
    emit('controlChangeValue', input);
  }
};
</script>
<template>
  <div class="flex w-full flex-col" data-test="editor-control-visibility">
    <slot name="label"></slot>
    <div class="flex flex-col gap-4">
      <div class="group/toggle flex h-[36px] items-center">
        <p class="text-text-dark-300 font-regular text-12">{{ t('Desktop') }}</p>
        <Toggler
          id="desktop"
          data-test="editor-control-visibility-desktop"
          :value="devicesValue?.desktop?.default"
          @control-change="change" />
      </div>
      <div class="group/toggle flex h-[36px] items-center">
        <p class="text-text-dark-300 font-regular text-12">{{ t('Tablet') }}</p>
        <Toggler
          id="tablet"
          data-test="editor-control-visibility-tablet"
          :value="devicesValue?.tablet?.default"
          @control-change="change" />
      </div>
      <div class="group/toggle flex h-[36px] items-center">
        <p class="text-text-dark-300 font-regular text-12">{{ t('Mobile') }}</p>
        <Toggler
          id="mobile"
          data-test="editor-control-visibility-mobile"
          :value="devicesValue?.mobile?.default"
          @control-change="change" />
      </div>
    </div>
  </div>
</template>
