<script lang="ts" setup>
import type { ColorProps } from '../global-color/types';
import BorderMoreSetting from './BorderMoreSetting.vue';
import type { BorderType, CommonBorder } from './types';
import NumberUnit from '../input/NumberUnit.vue';

type Props = {
  id: string;
  value?: BorderType;
  adv?: boolean;
  globalColors?: ColorProps[];
  colorMapping?: Record<string, string>;
  options?: CommonBorder[];
  emptyOnClear?: boolean;
};

const emit = defineEmits<{
  (e: 'controlChange', controlId?: string, value?: BorderType): void;
  (e: 'controlOnChange', controlId?: string, value?: BorderType): void;
}>();
const props = defineProps<Props>();

const isCustom = (data?: BorderType) => {
  const borderValues = data?.width?.split(' ');
  return borderValues?.some((val) => val !== borderValues[0]);
};

const change = (data: BorderType, type: 'controlOnChange' | 'controlChange') => {
  if (isCustom(data)) data.borderWidth = 'Mixed';
  else {
    data.borderWidth = data.width?.split(' ')[0] ?? '0';
  }
  data.isCustom = true;
  if (type === 'controlChange') emit('controlChange', props.id, data);
  else emit('controlOnChange', props.id, data);
};

const updateBorderWidth = (value: string, type: 'controlOnChange' | 'controlChange') => {
  change(
    {
      ...props.value,
      borderWidth: value,
      width: value === 'Mixed' ? props.value?.width : generateBorderWidthStyle(value || '0'),
    },
    type,
  );
};

const handleChangeBorderWidth = (value: string) => {
  updateBorderWidth(value, 'controlChange');
};

const handleOnChangeBorderWidth = (value: string) => {
  updateBorderWidth(value, 'controlOnChange');
};

const handleChangeBorderMoreSetting = (data: BorderType) => {
  change(data, 'controlChange');
};

const handleOnChangeBorderMoreSetting = (data: BorderType) => {
  change(data, 'controlOnChange');
};

const generateBorderWidthStyle = (width: string) => {
  width = width.replace('px', '');
  if (isNaN(parseFloat(width))) width = '0';
  return `${width}px ${width}px ${width}px ${width}px`;
};
</script>

<template>
  <div class="flex w-full justify-end">
    <div class="flex h-36 justify-end">
      <NumberUnit
        :value="value?.borderWidth"
        :min="0"
        :convert-null-to-min="true"
        :use-only-unit-init="true"
        :units="value?.borderWidth === 'Mixed' ? [] : ['px']"
        :hide-unit="value?.borderWidth === 'Mixed'"
        :placeholder="t('Auto')"
        class="mr-8 !w-[56px]"
        @change="handleChangeBorderWidth"
        @on-change="handleOnChangeBorderWidth" />
      <BorderMoreSetting
        :id="id"
        :value="value"
        :global-colors="globalColors"
        :color-mapping="colorMapping"
        @control-change="handleChangeBorderMoreSetting"
        @control-on-change="handleOnChangeBorderMoreSetting" />
    </div>
  </div>
</template>
