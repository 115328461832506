<script setup lang="ts">
import { useFloating, offset, shift, autoUpdate } from '@floating-ui/vue';
import { ref, computed, nextTick } from 'vue';
import { onClickOutside } from '@vueuse/core';
import type { GlobalSwatchesData, SwatchesOptionType } from '@gem/common';
import type { Maybe } from '../../type/graphql';
import type { ProductOption } from '../../type/common';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

const props = defineProps<{
  dataSwatches?: GlobalSwatchesData[];
  enableSort?: boolean;
  option: ProductOption;
}>();

const emit = defineEmits<{
  (e: 'changeOptionType', value: GlobalSwatchesData[]): void;
  (e: 'openModalEditStyle', optionType: SwatchesOptionType): void;
}>();

const DEFAULT_STYLE = {
  name: 'dropdown' as SwatchesOptionType,
  icon: 'variant-style-dropdown',
};
const openSelectStyle = ref(false);
const isFixed = ref(false);
const floating = ref<HTMLElement | null>(null);
const reference = ref<HTMLElement | null>(null);
const optionItem = ref<HTMLElement | null>(null);
const { y, strategy } = useFloating(reference, floating, {
  placement: 'bottom',
  middleware: [offset(16), shift()],
  whileElementsMounted: autoUpdate,
});
const dataSwatches = computed(() => props.dataSwatches);
const isShopifyDefaultOption = computed(() => {
  return props.option.name !== 'Title';
});
const initSwatchesData = (): GlobalSwatchesData[] => {
  try {
    return JSON.parse(JSON.stringify(dataSwatches.value || []));
  } catch (error) {
    console.warn(error);
    return [];
  }
};
const currentStyle = computed(
  () => dataSwatches.value?.find((item) => item.optionTitle === props.option.name)?.optionType || 'dropdown',
);

const STYLE_OPTIONS = [
  {
    label: 'Dropdown',
    value: 'dropdown' as SwatchesOptionType,
    icon: 'variant-style-dropdown-light',
    enableEdit: false,
  },
  {
    label: 'Button selection',
    value: 'rectangle_list' as SwatchesOptionType,
    icon: 'variant-style-list-light',
    enableEdit: false,
  },
  {
    label: 'Color swatch',
    value: 'color' as SwatchesOptionType,
    icon: 'variant-style-color-light',
    enableEdit: true,
  },
  {
    label: 'Shopify image',
    value: 'image_shopify' as SwatchesOptionType,
    icon: 'variant-style-image-shopify-light',
    enableEdit: false,
  },
  {
    label: 'Upload image',
    value: 'image' as SwatchesOptionType,
    icon: 'variant-style-image-light',
    enableEdit: true,
  },
];

const getOptionIconByName = (optionName?: string) => {
  const optionTypeConfig =
    dataSwatches.value?.find((item) => item.optionTitle === optionName)?.optionType || 'dropdown';
  switch (optionTypeConfig) {
    case 'dropdown':
      return 'variant-style-dropdown';
    case 'color':
      return 'variant-style-color';
    case 'image':
      return 'variant-style-image';
    case 'rectangle_list':
      return 'variant-style-list';
    case 'image_shopify':
      return 'variant-style-image-shopify';
    case 'radio_buttons':
      convertRadioToDefault();
      return;
    default:
      return DEFAULT_STYLE.icon;
  }
};

const convertRadioToDefault = () => {
  if (currentStyle.value === 'radio_buttons') {
    handleChangeOptionTypeSwatchesData(DEFAULT_STYLE.name, props.option.name);
  }
};

onClickOutside(floating, () => {
  if (!openSelectStyle.value) return;
  openSelectStyle.value = false;
});

const onOpenSelectStyle = () => {
  openSelectStyle.value = !openSelectStyle.value;
  if (openSelectStyle.value) {
    nextTick(() => {
      if (!floating.value) return;

      const floatingHeight = floating.value.offsetHeight;
      if (!optionItem.value) return;
      const bounding = optionItem.value.getBoundingClientRect();
      const spaceBelow = window.innerHeight - bounding.bottom;
      isFixed.value = floatingHeight > spaceBelow;
    });
  }
};

const handleChangeOptionTypeSwatchesData = (optionType: SwatchesOptionType, optionTitle: Maybe<string>) => {
  if (optionType === currentStyle.value) return;
  const optionChange = dataSwatches.value?.find((item) => item.optionTitle === optionTitle);
  let dataClone = initSwatchesData();
  if (optionChange) {
    dataClone = dataClone.map((item) => {
      if (item.optionTitle === optionTitle) {
        return {
          ...item,
          optionType: optionType,
        };
      }
      return item;
    });
  } else {
    const newItem: GlobalSwatchesData = {
      optionTitle: optionTitle as string,
      optionType: optionType,
      optionValues: [
        {
          label: 'New Item',
          colors: [],
          imageUrl: '',
        },
      ],
    };
    dataClone = [...dataClone, newItem];
  }
  emit('changeOptionType', dataClone);
};
const handleOpenModalEditStyle = (optionType: SwatchesOptionType) => {
  emit('openModalEditStyle', optionType);
};
</script>

<template>
  <div
    v-if="isShopifyDefaultOption"
    ref="optionItem"
    class="rounded-medium bg-dark-400 relative mb-8 flex h-40 select-none items-center justify-between overflow-hidden p-8 pl-4"
    :class="{
      'cursor-ns-resize': enableSort,
    }">
    <div class="relative z-10 flex items-center">
      <span
        v-if="enableSort"
        class="hover:text-light-450 text-text-dark-300 inline-flex cursor-pointer p-4 transition-all duration-200">
        <g-base-icon name="item-drag" width="16" height="16" viewBox="0 0 16 16"></g-base-icon>
      </span>
      <span
        class="text-12 text-text-dark-300 line-clamp-1 ml-8 max-w-[70px] overflow-hidden text-ellipsis font-medium"
        :class="{ 'max-w-[52px]': enableSort, 'max-w-[70px]': !enableSort }">
        {{ option.name }}</span
      >
      <g-base-icon
        class="ml-8"
        :name="getOptionIconByName(option.name)"
        width="62"
        height="24"
        viewBox="0 0 62 24"></g-base-icon>
    </div>
    <GButtonV2 ref="reference" type="secondary" size="small" @click="onOpenSelectStyle">{{ t('Change') }}</GButtonV2>
  </div>

  <Teleport to="#root-modal">
    <Transition>
      <div
        v-if="openSelectStyle"
        ref="floating"
        :class="{ '!bottom-[16px] !top-auto': isFixed }"
        :style="{
          position: strategy,
          top: y !== null ? `${y}px` : '',
          left: '16px',
        }"
        class="bg-dark-400 rounded-medium shadow-control-color-picker left-16 z-[999] w-[315px]">
        <div class="text-light-450 flex items-center justify-between p-16">
          {{ t('Change style for all products') }}
          <GButtonV2
            type="ghost"
            size="small"
            only-icon="close-more-setting"
            icon-view-box="0 0 16 16"
            @click="openSelectStyle = false">
          </GButtonV2>
        </div>
        <div class="px-16 pb-16">
          <div
            v-for="(item, index) in STYLE_OPTIONS"
            :key="index"
            class="rounded-medium text-light-450 text-12 border-dark-400 hover:border-dark-300 hover:bg-dark-300 mb-12 flex h-[48px] cursor-pointer items-center justify-between overflow-hidden border pr-12 last:mb-0"
            :class="{ '!border-primary-300 !bg-dark-400': item.value === currentStyle }"
            @click="handleChangeOptionTypeSwatchesData(item.value, option.name)">
            <div class="flex h-full items-center">
              <div class="rounded-medium bg-dark-250 flex h-full items-center">
                <g-base-icon :name="item.icon" width="90px" viewBox="0 0 90 48" fill="none"></g-base-icon>
              </div>
              <span class="pl-16 pr-12">{{ item.label }}</span>
            </div>
            <GButtonV2
              v-if="item.enableEdit && item.value === currentStyle"
              type="secondary"
              size="small"
              @click.stop="handleOpenModalEditStyle(item.value)">
              {{ t('Edit') }}
            </GButtonV2>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>
