<script lang="ts" setup>
import { computed } from 'vue';
import { Segment } from '@gem/control';
import { getMinValueForDiscountPrice, getSubTitle, getNewRangeValue, getMessageForValidate } from '../common';
import { CompareType, DiscountType } from '../constant';
import Wrapper from '../Wrapper/Index.vue';
import PopoverContent from '../Wrapper/PopoverContent.vue';
import RangeInput from '../common/RangeInput.vue';
import type { DiscountPriceTrigger } from '../type';
import CompareTypeSelect from '../common/CompareTypeSelect.vue';
import InputBadge from '../common/InputBadge.vue';

const props = defineProps<{
  data: DiscountPriceTrigger;
  moneyFormat?: string;
  isLoading?: boolean;
}>();

const emit = defineEmits<{
  (e: 'onDelete'): void;
  (event: 'onUpdate', data: DiscountPriceTrigger): void;
}>();

const typeOptions = [
  {
    label: '%',
    value: DiscountType.PERCENT,
  },
  {
    label: 'Fixed',
    value: DiscountType.FIXED,
  },
];

const subTitle = computed(() => {
  const { discountType, conditionType, from, to } = props.data;
  const isPercentage = discountType === DiscountType.PERCENT;
  return getSubTitle(conditionType, from, to, isPercentage ? '{{amount}}%' : props.moneyFormat);
});

const onChange = (_: string, value: DiscountType) => {
  emit('onUpdate', {
    ...props.data,
    discountType: value,
  });
};

const onChangeQuantityValue = (name: string, value: string) => {
  emit('onUpdate', {
    ...props.data,
    [name]: value,
  });
};

const min = computed(() => {
  const { conditionType } = props.data;
  return getMinValueForDiscountPrice(conditionType as CompareType);
});

const onChangeConditionType = (value: CompareType) => {
  const minCurr = getMinValueForDiscountPrice(value);
  const { from, to } = getNewRangeValue(props.data.from, props.data.to ?? '', minCurr, value);
  emit('onUpdate', {
    ...props.data,
    type: value,
    conditionType: value,
    from,
    to,
  });
};

const message = computed(() => {
  const { conditionType, from, to } = props.data;
  return getMessageForValidate({
    from,
    to,
    type: conditionType as CompareType,
    trigger: 'discount_price',
  });
});
</script>

<template>
  <Wrapper :title="t('Discount price')" :sub-title="subTitle" :is-loading="isLoading" @on-delete="emit('onDelete')">
    <PopoverContent :title="t('Type')">
      <div class="w-[140px]">
        <Segment
          class="h-36 !w-[140px]"
          background-class="bg-dark-400"
          :value="data.discountType"
          :options="typeOptions"
          @control-change="onChange" />
      </div>
    </PopoverContent>
    <PopoverContent :title="t('Amount')">
      <CompareTypeSelect :quantity-type="data?.type" @on-change="onChangeConditionType" />
      <RangeInput
        v-if="data?.type === CompareType.BETWEEN"
        :quantity-type="data?.type"
        :from="data.from"
        :to="data.to"
        :min="1"
        @on-update-value="onChangeQuantityValue" />
      <InputBadge v-else id="from" :value="data?.from" :min="min" @on-change="onChangeQuantityValue" />
      <template #message>
        <div v-if="message" class="text-12 flex items-start gap-8 text-red-200">
          <g-base-icon name="info" width="20" height="20" viewBox="0 0 16 16" />
          <p>{{ message }}</p>
        </div>
      </template>
    </PopoverContent>
  </Wrapper>
</template>
