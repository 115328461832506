<script lang="ts" setup>
import { GButton } from '@gem/uikit-v2';
import { replaceImageToSize } from '../../../../hook/useResizeImage';
import ArchivedStatus from '../../../../icons/ArchivedStatus.vue';
import DraftStatus from '../../../../icons/DraftStatus.vue';
import { useHandleArrangeProducts } from '../hooks/useHandleArrangeProducts';
import { useHandleDataMultiProductPicker } from '../hooks/useHandleDataMultiProductPicker';
import type { MultiProductPickerEmit, MultiProductPickerProps } from '../type';
import ArrangeProduct from './ArrangeProduct.vue';
import GCheckboxPicker from './GCheckboxPicker.vue';
import { tooltipIconStatusContent } from '../helper';
import SkeletonProductList from './SkeletonProductList.vue';
import BannerWarningSelect from './BannerWarningSelect.vue';
import { computed } from 'vue';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

const props = defineProps<MultiProductPickerProps>();
const emit = defineEmits<MultiProductPickerEmit>();
const {
  productInfinityScroll,
  isPostPurchasePage,
  setValueSelected,
  productList,
  save,
  isDisabledSave,
  handleSelectProductVariant,
  closeAlertPick,
  contentAlertPick,
  handleUnselectedAll,
  isDisableUnselectAll,
  checkShowVariantTitle,
} = useHandleDataMultiProductPicker(emit, props);

const { handleSubmitReorder, handleUnselected, closeAlertArrange, contentAlertRange } = useHandleArrangeProducts(
  emit,
  props,
);

const isShowNoProductPicker = computed(() => (props.list ?? []).length === 0 && !props.isLoading);

defineExpose({
  save,
  isDisabledSave,
});
</script>
<template>
  <div
    v-if="stepPicker === 'ARRANGE'"
    :class="{
      '!max-h-[363px]': isPostPurchasePage,
      '!max-h-[319px]': !isPostPurchasePage,
    }">
    <ArrangeProduct
      :selectedProductList="selectedProductList ?? []"
      :variantSelected="variantSelected"
      :pageType="pageType"
      :handleSubmitReorder="handleSubmitReorder"
      :handleUnselected="handleUnselected"
      :contentAlertRange="contentAlertRange"
      :closeAlert="closeAlertArrange"
      :isLoading="isLoadingArrange"
      @select-variant="handleSelectProductVariant" />
  </div>
  <div v-else class="!max-h-[319px]">
    <div
      v-if="isShowNoProductPicker"
      class="flex h-[336px] max-h-[calc(100vh-258px)] w-full flex-col items-center justify-center">
      <img class="h-[80px] w-[80px] rounded-xl" src="../../../images/searchEmpty.png" alt="collections" />
      <div v-if="isNotHaveProduct" class="text-12 font-regular text-text-dark-300 text-center leading-5">
        {{ t('No product found in your store.') }}<br />
        <a class="text-primary-200 cursor-pointer no-underline" type="link" @click="navigateToProductShopify"
          >{{ t('Create new in Shopify') }}
        </a>
        {{ t('and try again.') }}
      </div>
      <div v-else class="text-12 font-regular text-text-dark-300 text-center leading-5">
        {{ t('No product match your search.') }}<br />{{ t('Try another keyword') }}
      </div>
    </div>
    <div v-else>
      <BannerWarningSelect :content="contentAlertPick" :close-alert="closeAlertPick" />
      <div class="mb-4 flex justify-between px-16">
        <p class="text-text-dark-300 font-regular text-12 leading-5">{{ productList.length }} products selected</p>
        <GButton
          class="text-primary-200 text-12 h-20 cursor-pointer font-medium leading-5 no-underline"
          type="link"
          @click="handleUnselectedAll"
          :disable="isDisableUnselectAll"
          >Unselect all
        </GButton>
      </div>
      <perfect-scrollbar
        ref="productInfinityScroll"
        :class="{
          'max-height-list-multiple-warning !h-[231px]': !!contentAlertPick,
          'max-height-list-multiple !h-[303px]': !contentAlertPick,
        }">
        <SkeletonProductList v-if="isLoading" :items-length="5" height="319px" />
        <div v-else class="flex flex-col px-16">
          <div
            v-for="(item, index) in list"
            :key="index"
            data-test="setting-modal-product-item"
            class="active:border-primary-300 hover:bg-dark-300 flex cursor-pointer items-center gap-8 rounded-[12px] border border-transparent p-8"
            @click="setValueSelected(item)"
            @keypress="setValueSelected(item)">
            <GCheckboxPicker
              :value="productList?.find((i) => i.id === item.id) ? true : false"
              class="pointer-events-none relative top-[2px] -mt-10 ml-auto !w-auto" />
            <div
              class="h-[40px] w-[40px] flex-shrink-0 rounded-xl bg-transparent"
              :class="{
                'flex items-center justify-center': !item.image,
              }">
              <img
                v-if="item.image"
                class="h-full w-full rounded-xl object-cover"
                :src="replaceImageToSize(item?.image ?? '', '120x120') ?? item.image"
                alt="collections" />
              <img v-else class="h-full w-full rounded-xl" src="../../../images/no-product.png" alt="collections" />
            </div>
            <div
              class="text-12 line-clamp-2 text-text-dark-500 font-regular w-full truncate whitespace-pre-wrap break-words leading-5">
              <p :class="`line-clamp-${isPostPurchasePage && checkShowVariantTitle(item) ? 1 : 2}`">
                {{ item?.title }}
              </p>
              <p v-if="checkShowVariantTitle(item)" class="text-text-light-300 line-clamp-1">
                {{ variantSelected?.[item.id]?.variantTitle ?? 'All variant' }}
              </p>
            </div>
            <div v-if="item.status?.toLowerCase() !== 'active'" class="flex items-center">
              <g-tooltip placement="top" wrapper-class="w-full" content-class="!whitespace-normal" :is-teleport="true">
                <DraftStatus v-if="item?.status?.toLowerCase() === 'draft'" />
                <ArchivedStatus v-else />
                <template #content>
                  <div class="text-text-dark-500 font-regular text-12 leading-5">
                    {{ tooltipIconStatusContent(item?.status?.toLowerCase()) }}
                  </div>
                </template>
              </g-tooltip>
            </div>
          </div>
          <GButton v-if="isLoadMore" :loading="isLoadMore" size="medium" type="ghost" only-icon="polaris-refresh" />
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>
<style lang="css" scoped>
@media (max-height: 580px) {
  .max-height-list-multiple {
    max-height: calc(100vh - 282px) !important;
  }

  .max-height-list-multiple-warning {
    max-height: calc(100vh - 350px) !important;
  }
}

.max-height-list-multiple {
  max-height: 303px;
}
</style>
