<script lang="ts" setup>
import type { Ref } from 'vue';
import type { OptionSelect } from '@gem/uikit';
import type { Placement } from '@floating-ui/vue';
import { ref, watch } from 'vue';
import { flip, offset, shift, useFloating } from '@floating-ui/vue';
import SelectButton from './TypographyStyleSelect/SelectButton.vue';
import SelectDropdown from './TypographyStyleSelect/SelectDropdown.vue';
import SelectOptions from './TypographyStyleSelect/SelectOptions.vue';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

type Props = {
  id?: string;
  name?: string;
  disabled?: boolean;
  floating?: boolean;
  placement?: Placement;
  displayValue?: string;
  options: OptionSelect[];
  activeId?: string | number;
};

const props = withDefaults(defineProps<Props>(), {
  options: () => [],
  disabled: false,
  floating: true,
  placement: 'bottom-end',
});

const emit = defineEmits<{
  (e: 'select', value: any, id: string, selectName?: string): void;
  (e: 'on-click-sub-action', type: string): void;
}>();

const button = ref<HTMLElement>();
const select = ref<HTMLElement>();
const filteredOptions = ref(props.options);
const isOpenSelect: Ref<boolean> = ref(false);
const isHoverSelect: Ref<boolean> = ref(false);
const isDisplayOnBottom: Ref<boolean> = ref(true);

const { x, y, strategy } = useFloating(button, select, {
  placement: props.placement,
  middleware: [flip(), shift(), offset(4)],
});

const toggleSelect = () => {
  isOpenSelect.value ? closeSelect() : openSelect();
};

const openSelect = () => {
  isOpenSelect.value = true;
};

const closeSelect = () => {
  isOpenSelect.value = false;
  resetStateDisplay();
};

const resetStateDisplay = () => {
  isDisplayOnBottom.value = true;
  isHoverSelect.value = false;
};

const onSelect = (item: OptionSelect) => {
  if (!isActiveOption(item)) {
    emit('select', item.value, item.id, props.name);
  }
  closeSelect();
};

const isActiveOption = (item: OptionSelect) => {
  return String(props.activeId) === String(item.id);
};

const changeStatusHoverSelect = () => {
  isHoverSelect.value = true;
};

const handleEditGlobalStyle = () => {
  emit('on-click-sub-action', 'editGlobalStyle');
};

watch(
  () => props.options,
  (newValue) => {
    filteredOptions.value = newValue;
  },
);
</script>

<template>
  <div class="bg-dark-400 relative w-full rounded-xl">
    <SelectButton :id="id" ref="button" :disabled="disabled" :is-open="isOpenSelect" @toggle="toggleSelect">
      <template #button-content="{ isOpen }">
        <slot name="button-content" :is-open="isOpen" />
      </template>
    </SelectButton>
    <SelectDropdown
      v-if="isOpenSelect"
      ref="select"
      :floating="floating"
      :strategy="strategy"
      :dropdown-position-x="x"
      :dropdown-position-y="y"
      @close="closeSelect"
      @mouse-move="changeStatusHoverSelect">
      <SelectOptions
        :active-id="activeId"
        :is-hover-select="isHoverSelect"
        :filtered-options="filteredOptions"
        @select="onSelect" />
      <div class="mx-8 flex flex-col gap-8 px-8 pt-8 pb-4">
        <span class="border-dark-200 border-b" />
        <g-button
          type="ghost"
          size="small"
          class="text-14 text-primary-200 m-0 bg-transparent p-0 hover:bg-transparent hover:underline"
          @click="handleEditGlobalStyle">
          {{ t('Edit global style') }}
        </g-button>
      </div>
    </SelectDropdown>
  </div>
</template>
