<script lang="ts" setup>
import type { TriggerEvent, DisplayTrigger } from './type';
import { computed, ref } from 'vue';
import { GPopover, GButtonV2, GBaseIcon, GTooltip } from '@gem/uikit';
import { onClickOutside } from '@vueuse/core';
import { TriggerType, conditionProductBadgeDefault, triggerTypeLabel } from './constant';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

const popover = ref<any>(null);

const props = defineProps<{
  triggerEvent: DisplayTrigger[];
  isDisabledTrigger?: boolean;
  isLoading?: boolean;
}>();

const emit = defineEmits<{
  (e: 'onAdd', trigger: DisplayTrigger): void;
}>();

const existsTriggerEvents = computed(() => {
  return props.triggerEvent?.map((item) => item?.triggerEvent);
});

const addDisplayTrigger = (triggerEvent: TriggerEvent) => {
  const trigger: DisplayTrigger = conditionProductBadgeDefault[triggerEvent];
  if (trigger) emit('onAdd', trigger);
};

onClickOutside(popover, () => {
  if (popover.value) popover.value?.close();
});
</script>
<template>
  <GPopover
    ref="popover"
    :has-arrow="true"
    :overlay="false"
    overlay-container="#root-modal"
    button-class="!block"
    is-hidden-dom
    placement="bottom-end">
    <template #default="{ open, close }">
      <GTooltip
        placement="top"
        :disabled="!(isDisabledTrigger || isLoading)"
        content-class="w-[180px] !whitespace-normal break-words">
        <GButtonV2
          :disable="isDisabledTrigger || isLoading"
          icon-size="20"
          :loading="isLoading"
          size="medium"
          :type="open ? 'primaryGhost' : 'ghost'"
          only-icon="general-plus"
          :active="open"
          @click="close" />
        <template #content> {{ t('Assign or pick more product to add additional trigger') }}</template>
      </GTooltip>
    </template>
    <template #content>
      <div class="bg-dark-400 rounded-12 h-[180px] w-[232px]">
        <div
          v-for="triggerType in TriggerType"
          :key="triggerType"
          class="text-12 text-text-dark-500 hover:bg-dark-250 group flex cursor-pointer items-center gap-12 rounded-xl p-8"
          :class="{
            '!text-text-dark-100 hover:!bg-dark-400 !cursor-not-allowed': existsTriggerEvents.includes(triggerType),
          }"
          @click="() => (!existsTriggerEvents.includes(triggerType) ? addDisplayTrigger(triggerType) : undefined)">
          <p class="flex-1">{{ triggerTypeLabel[triggerType] }}</p>
          <GBaseIcon
            class="invisible group-hover:visible"
            :class="{
              '!invisible': existsTriggerEvents.includes(triggerType),
            }"
            name="general-plus"
            width="20"
            height="20" />
        </div>
      </div>
    </template>
  </GPopover>
</template>
