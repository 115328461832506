<script lang="ts" setup>
import type { AliSectionPickedType } from '@gem/common';
import { ref, watch } from 'vue';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

type Props = {
  defaultValue?: AliSectionPickedType;
  value?: AliSectionPickedType;
  pickSectionLabel?: string;
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'open'): void;
  (e: 'clear', value?: AliSectionPickedType): void;
}>();

const enteredInput = ref(!!props.value?.id.length);
const refInput = ref<HTMLInputElement>();
const val = ref<AliSectionPickedType>(props.value ?? props.defaultValue);

const onPickSection = () => {
  emit('open');
};
const handleClearSection = () => {
  emit('clear', props.defaultValue);
  refInput.value?.focus();
};

watch(
  () => props.value,
  (value) => {
    if (value !== undefined) val.value = value;
  },
);
</script>
<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <div>
      <div class="flex flex-col">
        <div class="flex items-start justify-between" :class="{ 'mb-16': enteredInput }">
          <div class="w-full">
            <div class="relative mb-8 w-full">
              <g-input
                ref="refInput"
                type="text"
                :disable="true"
                input-style="secondary"
                :placeholder="t('Eg: Carousel Slider')"
                :value="val?.name || ''" />
              <g-base-icon
                v-if="val?.id"
                name="close-round"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                class="text-light-450 absolute top-[calc(50%-8px)] right-8 cursor-pointer"
                @click="handleClearSection" />
            </div>
            <GButtonV2
              type="secondary"
              button-width="full"
              size="medium"
              icon-before="pick-link"
              @click="onPickSection">
              {{ pickSectionLabel || t('Pick a section') }}
            </GButtonV2>
          </div>
        </div>
      </div>
    </div>
    <slot name="info"></slot>
  </div>
</template>
