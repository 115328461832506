<template>
  <div class="modal flex h-full w-full items-center justify-center" :class="{ fixed: !contentFixed }">
    <div v-if="!hideOverlay" class="modal-bgdrop absolute h-full w-full bg-black opacity-60"></div>
    <div
      v-click-away="close"
      class="modal-content rounded-small relative overflow-hidden bg-white"
      :class="{
        'shadow-modal': hideOverlay,
        'modal-conent-confirm': type == 'confirm',
      }">
      <div v-if="hideHeader" class="modal-head flex items-center font-bold">
        <p class="text-18 mr-16 w-full truncate">
          <slot name="title"> </slot>
        </p>
        <button
          class="modal-btn-close rounded-small relative p-4 transition duration-200 hover:bg-gray-100"
          @click="close">
          <svg viewBox="0 0 24 24" fill="currentColor">
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
          </svg>
        </button>
      </div>
      <div class="modal-body flex-grow overflow-y-auto bg-gray-50">
        <slot></slot>
      </div>
      <div v-if="!hideActions" class="modal-footer flex items-center justify-end px-16 py-8">
        <GButtonV2 class="px-32" type="secondary" @click="emit('cancel'), close"> {{ t('Cancel') }} </GButtonV2>
        <GButtonV2 class="ml-8 px-40" type="primary" @click="emit('save'), close">{{ t('Save') }}</GButtonV2>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
type Props = {
  type?: 'confirm' | 'normal';
  hideOverlay?: boolean;
  hideActions?: boolean;
  contentFixed?: boolean;
  hideHeader?: boolean;
};
withDefaults(defineProps<Props>(), {
  type: 'normal',
  hideOverlay: false,
  hideActions: false,
  hideHeader: false,
  contentFixed: false,
});

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'save'): void;
  (e: 'cancel'): void;
}>();

const close = () => {
  emit('close');
};
</script>
<style lang="scss" scoped>
.modal {
  z-index: 999;
  top: 0;

  .modal-bgdrop {
    z-index: 20;
  }
  .modal-content {
    z-index: 50;
    width: fit-content;
    max-width: 90%;
    max-height: 90%;

    &.modal-conent-confirm {
      width: 400px;
    }

    .modal-head {
      position: relative;
      z-index: 20;
      height: 60px;
      box-shadow: rgb(0 0 0 / 4%) 0px 3px 5px;
      .modal-btn-close {
        svg {
          width: 24px;
          height: auto;
        }
      }
    }
    .modal-body {
      position: relative;
      z-index: 10;
    }
  }
}
</style>
