<script lang="ts" setup>
import { ref } from 'vue';
import { useI18n } from '@gem/i18n';

const { t } = useI18n({ useScope: 'global' });
type FlexObject = { direction: string; wrap: string; justifyContent: string; alignItem: string };
type Props = {
  id: string;
  value: FlexObject;
};
const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'controlChange', id: Props['id'], value: Props['value']): void;
  (e: 'update', value: string): void;
}>();

const val = ref(props.value);

function change() {
  emit('controlChange', props.id, val.value);
}

function handleClickOptions(type: keyof FlexObject, value: string) {
  switch (type) {
    case 'direction':
      val.value.direction = value;
      break;
    case 'justifyContent':
      val.value.justifyContent = value;
      break;
    case 'alignItem':
      val.value.alignItem = value;
      break;
    case 'wrap':
      val.value.wrap = value;
      break;
    default:
      break;
  }
  change();
}
</script>

<template>
  <div class="gt--control control--flex">
    <!-- flex direction -->
    <p>{{ t('Flex Direction') }}</p>
    <div class="gemx-control--flex__list">
      <div
        class="gemx-control--flex__item"
        :class="val.direction == 'column' && ' active'"
        @click="handleClickOptions('direction', 'column')">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="30" width="30" height="30" transform="rotate(90 30 0)" fill="#111111" />
          <rect x="22" y="3" width="4" height="20" transform="rotate(90 22 3)" fill="#C4C4C4" />
          <rect x="22" y="8" width="4" height="20" transform="rotate(90 22 8)" fill="#C4C4C4" />
          <rect x="22" y="13" width="4" height="20" transform="rotate(90 22 13)" fill="#C4C4C4" />
          <rect x="22" y="18" width="4" height="20" transform="rotate(90 22 18)" fill="#C4C4C4" />
          <rect x="22" y="23" width="4" height="20" transform="rotate(90 22 23)" fill="#C4C4C4" />
          <path
            d="M25.7172 25.2828C25.8734 25.4391 26.1266 25.4391 26.2828 25.2828L28.8284 22.7373C28.9846 22.581 28.9846 22.3278 28.8284 22.1716C28.6722 22.0154 28.419 22.0154 28.2627 22.1716L26 24.4343L23.7373 22.1716C23.581 22.0154 23.3278 22.0154 23.1716 22.1716C23.0154 22.3278 23.0154 22.581 23.1716 22.7373L25.7172 25.2828ZM25.6 3L25.6 25H26.4L26.4 3H25.6Z"
            fill="#C4C4C4" />
        </svg>
      </div>
      <div
        class="gemx-control--flex__item"
        :class="val.direction == 'column-reverse' && ' active'"
        @click="handleClickOptions('direction', 'column-reverse')">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="30" width="30" height="30" transform="rotate(90 30 0)" fill="#111111" />
          <rect x="22" y="3" width="4" height="20" transform="rotate(90 22 3)" fill="#C4C4C4" />
          <rect x="22" y="8" width="4" height="20" transform="rotate(90 22 8)" fill="#C4C4C4" />
          <rect x="22" y="13" width="4" height="20" transform="rotate(90 22 13)" fill="#C4C4C4" />
          <rect x="22" y="18" width="4" height="20" transform="rotate(90 22 18)" fill="#C4C4C4" />
          <rect x="22" y="23" width="4" height="20" transform="rotate(90 22 23)" fill="#C4C4C4" />
          <path
            d="M26.2828 2.71716C26.1266 2.56095 25.8734 2.56095 25.7172 2.71716L23.1716 5.26274C23.0154 5.41895 23.0154 5.67222 23.1716 5.82843C23.3278 5.98464 23.581 5.98464 23.7373 5.82843L26 3.56569L28.2627 5.82843C28.419 5.98464 28.6722 5.98464 28.8284 5.82843C28.9846 5.67222 28.9846 5.41895 28.8284 5.26274L26.2828 2.71716ZM25.6 3L25.6 25H26.4L26.4 3H25.6Z"
            fill="#C4C4C4" />
        </svg>
      </div>
    </div>
    <!-- wrap -->
    <p>{{ t('Wrap') }}</p>
    <div class="gemx-control--flex__list">
      <div
        class="gemx-control--flex__item"
        :class="val.wrap == 'nowrap' && ' active'"
        @click="handleClickOptions('wrap', 'nowrap')">
        <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="30" width="30" height="30" transform="rotate(90 30 0)" fill="#111111" />
          <rect x="28" y="2" width="4" height="26" transform="rotate(90 28 2)" fill="#C4C4C4" />
          <rect x="28" y="7" width="4" height="26" transform="rotate(90 28 7)" fill="#C4C4C4" />
          <rect x="28" y="12" width="4" height="26" transform="rotate(90 28 12)" fill="#C4C4C4" />
          <rect x="28" y="17" width="4" height="26" transform="rotate(90 28 17)" fill="#C4C4C4" />
          <rect x="28" y="22" width="4" height="26" transform="rotate(90 28 22)" fill="#C4C4C4" />
          <rect x="28" y="27" width="4" height="26" transform="rotate(90 28 27)" fill="#C4C4C4" />
        </svg>
      </div>
      <div
        class="gemx-control--flex__item"
        :class="val.wrap == 'wrap' && ' active'"
        @click="handleClickOptions('wrap', 'wrap')">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="30" width="30" height="30" transform="rotate(-90 0 30)" fill="#111111" />
          <rect x="2" y="29" width="4" height="12" transform="rotate(-90 2 29)" fill="#C4C4C4" />
          <rect x="2" y="23" width="4" height="12" transform="rotate(-90 2 23)" fill="#C4C4C4" />
          <rect x="2" y="17" width="4" height="12" transform="rotate(-90 2 17)" fill="#C4C4C4" />
          <rect x="2" y="11" width="4" height="12" transform="rotate(-90 2 11)" fill="#C4C4C4" />
          <rect x="2" y="5" width="4" height="12" transform="rotate(-90 2 5)" fill="#C4C4C4" />
          <rect x="16" y="11" width="4" height="12" transform="rotate(-90 16 11)" fill="#C4C4C4" />
          <rect x="16" y="5" width="4" height="12" transform="rotate(-90 16 5)" fill="#C4C4C4" />
        </svg>
      </div>
    </div>
    <!-- justify Content -->
    <p>{{ t('Justify Content') }}</p>
    <div class="gemx-control--flex__list">
      <!-- justify Content start -->
      <div
        class="gemx-control--flex__item"
        :class="val.justifyContent == 'flex-start' && ' active'"
        @click="handleClickOptions('justifyContent', 'flex-start')">
        <svg
          v-if="val.direction == 'column' || val.direction == ''"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect x="30" width="30" height="30" transform="rotate(90 30 0)" fill="#111111" />
          <rect x="28" y="1" width="4" height="26" transform="rotate(90 28 1)" fill="#C4C4C4" />
          <rect x="28" y="6" width="4" height="26" transform="rotate(90 28 6)" fill="#C4C4C4" />
        </svg>
        <svg v-else width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="30" width="30" height="30" transform="rotate(-90 0 30)" fill="#111111" />
          <rect x="2" y="29" width="4" height="26" transform="rotate(-90 2 29)" fill="#C4C4C4" />
          <rect x="2" y="24" width="4" height="26" transform="rotate(-90 2 24)" fill="#C4C4C4" />
        </svg>
      </div>
      <!-- justify Content end -->

      <div
        class="gemx-control--flex__item"
        :class="val.justifyContent == 'flex-end' && ' active'"
        @click="handleClickOptions('justifyContent', 'flex-end')">
        <svg
          v-if="val.direction == 'column' || val.direction == ''"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect y="30" width="30" height="30" transform="rotate(-90 0 30)" fill="#111111" />
          <rect x="2" y="29" width="4" height="26" transform="rotate(-90 2 29)" fill="#C4C4C4" />
          <rect x="2" y="24" width="4" height="26" transform="rotate(-90 2 24)" fill="#C4C4C4" />
        </svg>
        <svg v-else width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="30" width="30" height="30" transform="rotate(90 30 0)" fill="#111111" />
          <rect x="28" y="1" width="4" height="26" transform="rotate(90 28 1)" fill="#C4C4C4" />
          <rect x="28" y="6" width="4" height="26" transform="rotate(90 28 6)" fill="#C4C4C4" />
        </svg>
      </div>
      <!-- justify Content center -->

      <div
        class="gemx-control--flex__item"
        :class="val.justifyContent == 'center' && ' active'"
        @click="handleClickOptions('justifyContent', 'center')">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="30" width="30" height="30" transform="rotate(-90 0 30)" fill="#111111" />
          <rect x="2" y="20" width="4" height="26" transform="rotate(-90 2 20)" fill="#C4C4C4" />
          <rect x="2" y="15" width="4" height="26" transform="rotate(-90 2 15)" fill="#C4C4C4" />
        </svg>
      </div>
      <!-- justify Content between -->

      <div
        class="gemx-control--flex__item"
        :class="val.justifyContent == 'space-between' && ' active'"
        @click="handleClickOptions('justifyContent', 'space-between')">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="30" width="30" height="30" transform="rotate(-90 0 30)" fill="#111111" />
          <rect x="2" y="28" width="4" height="26" transform="rotate(-90 2 28)" fill="#C4C4C4" />
          <rect x="2" y="6" width="4" height="26" transform="rotate(-90 2 6)" fill="#C4C4C4" />
        </svg>
      </div>
      <!-- justify Content around -->
      <div
        class="gemx-control--flex__item"
        :class="val.justifyContent == 'space-around' && ' active'"
        @click="handleClickOptions('justifyContent', 'space-around')">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="30" width="30" height="30" transform="rotate(-90 0 30)" fill="#111111" />
          <rect x="2" y="26" width="8" height="26" transform="rotate(-90 2 26)" fill="#C4C4C4" />
          <rect x="2" y="9" width="4" height="26" transform="rotate(-90 2 9)" fill="#C4C4C4" />
        </svg>
      </div>
      <!-- justify Content evenly -->
      <div
        class="gemx-control--flex__item"
        :class="val.justifyContent == 'space-evenly' && ' active'"
        @click="handleClickOptions('justifyContent', 'space-evenly')">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="30" width="30" height="30" transform="rotate(-90 0 30)" fill="#111111" />
          <rect x="2" y="22" width="4" height="26" transform="rotate(-90 2 22)" fill="#C4C4C4" />
          <rect x="2" y="12" width="4" height="26" transform="rotate(-90 2 12)" fill="#C4C4C4" />
        </svg>
      </div>
    </div>
    <!-- align item -->
    <p>{{ t('Align Items') }}</p>
    <div class="gemx-control--flex__list">
      <!-- align item stretch-->
      <div
        class="gemx-control--flex__item"
        :class="val.alignItem == 'stretch' && ' active'"
        @click="handleClickOptions('alignItem', 'stretch')">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="30" width="30" height="30" transform="rotate(90 30 0)" fill="#111111" />
          <rect x="28" y="1" width="4" height="26" transform="rotate(90 28 1)" fill="#C4C4C4" />
          <rect x="28" y="6" width="4" height="26" transform="rotate(90 28 6)" fill="#C4C4C4" />
        </svg>
      </div>
      <!-- align item center-->
      <div
        class="gemx-control--flex__item"
        :class="val.alignItem == 'center' && ' active'"
        @click="handleClickOptions('alignItem', 'center')">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="30" width="30" height="30" transform="rotate(90 30 0)" fill="#111111" />
          <circle cx="16.5" cy="3.5" r="2.5" transform="rotate(90 16.5 3.5)" fill="#C4C4C4" />
          <path
            d="M16.5 12C15.1193 12 14 10.8807 14 9.5C14 8.11929 15.1193 7 16.5 7C17.8807 7 19 8.11929 19 9.5C19 10.8807 17.8807 12 16.5 12Z"
            fill="#C4C4C4" />
        </svg>
      </div>
      <!-- align item start-->
      <div
        class="gemx-control--flex__item"
        :class="val.alignItem == 'flex-start' && ' active'"
        @click="handleClickOptions('alignItem', 'flex-start')">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="30" width="30" height="30" transform="rotate(90 30 0)" fill="#111111" />
          <circle cx="4.5" cy="4.5" r="2.5" transform="rotate(90 4.5 4.5)" fill="#C4C4C4" />
          <path
            d="M4.5 13C3.11929 13 2 11.8807 2 10.5C2 9.11929 3.11929 8 4.5 8C5.88071 8 7 9.11929 7 10.5C7 11.8807 5.88071 13 4.5 13Z"
            fill="#C4C4C4" />
        </svg>
      </div>
      <!-- align item end-->
      <div
        class="gemx-control--flex__item"
        :class="val.alignItem == 'flex-end' && ' active'"
        @click="handleClickOptions('alignItem', 'flex-end')">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="30" width="30" height="30" transform="rotate(90 30 0)" fill="#111111" />
          <circle cx="25.5" cy="4.5" r="2.5" transform="rotate(90 25.5 4.5)" fill="#C4C4C4" />
          <path
            d="M25.5 13C24.1193 13 23 11.8807 23 10.5C23 9.11929 24.1193 8 25.5 8C26.8807 8 28 9.11929 28 10.5C28 11.8807 26.8807 13 25.5 13Z"
            fill="#C4C4C4" />
        </svg>
      </div>
      <!-- align item baseline-->
      <div
        class="gemx-control--flex__item"
        :class="val.alignItem == 'baseline' && ' active'"
        @click="handleClickOptions('alignItem', 'baseline')">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="30" height="30" fill="#111111" />
          <path
            d="M1.62114 11H0.572843L2.92831 4.45455H4.06929L6.42476 11H5.37646L3.52597 5.64347H3.47483L1.62114 11ZM1.79692 8.43679H5.19749V9.26776H1.79692V8.43679Z"
            fill="white" />
          <path
            d="M2.07733 23H0.446644L4.11071 12.8182H5.88556L9.54963 23H7.91895L5.04039 14.6676H4.96085L2.07733 23ZM2.35076 19.0128H7.64054V20.3054H2.35076V19.0128Z"
            fill="white" />
        </svg>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.control--flex {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #424242;
  border: 1px solid #242424;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  .control--flex__list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 2px;

    .control--flex__item {
      display: flex;
      justify-content: center;
      width: 50%;
      background-color: #424242;
      border: 1px solid #242424;
      height: 30px;
      overflow: hidden;
      align-items: center;
      &:hover {
        background-color: #242424;
      }
    }
  }
}
.active {
  background-color: #fff !important;
}
</style>
