<script lang="ts" setup>
import { computed, ref } from 'vue';
import { getFactors } from '../../helpers/math';
import type { ScreenType } from '../../types';
import GridTemplateColumn from './custom-layout/GridTemplateColumn.vue';
import { gridToArray } from './hooks';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

const props = withDefaults(
  defineProps<{
    value?: string;
    fallback?: string;
    maxCol?: number;
    device?: ScreenType;
  }>(),
  {
    maxCol: 1,
  },
);

const emit = defineEmits<{
  (e: 'change', value?: string): void;
}>();

const openCustom = ref(false);

const layouts = computed(() => {
  const factors = getFactors(props.maxCol);
  if (props.device === 'desktop')
    return Array.from({ length: props.maxCol }).map((_, index) => {
      return Array.from({ length: index + 1 })
        .map(() => {
          return `minmax(0, 1fr)`;
        })
        .join(' ');
    });
  return factors.map((factor) => {
    return Array.from({ length: factor })
      .map(() => {
        return `minmax(0, 1fr)`;
      })
      .join(' ');
  });
});

const handleChangeLayout = (layout?: string) => {
  emit('change', layout);
};

const currentSelectLayout = computed(() => {
  return props.value ?? props.fallback;
});

const customClick = () => {
  openCustom.value = true;
  // emit('change-screen', props.device);
};

const closeCustom = () => {
  openCustom.value = false;
};

// const isRecommend = computed(() => {
//   return layouts.value.some(
//     (item) =>
//       item === currentSelectLayout.value ||
//       (!!props.value && gridToArray(item).length === 1 && gridToArray(props.value).length === 1),
//   );
// });
</script>

<template>
  <slot></slot>
  <div class="relative">
    <TransitionGroup name="slide" tag="div">
      <div v-if="!openCustom" class="grid w-full grid-cols-3 gap-8 py-8">
        <div
          v-for="item in layouts"
          :key="item"
          class="grid h-32 max-w-full cursor-pointer gap-[1px]"
          :style="{ gridTemplateColumns: item }"
          @click="handleChangeLayout(item === value ? undefined : item)">
          <span
            v-for="(_, index) in gridToArray(item)"
            :key="index"
            class="rounded-medium"
            :class="{
              'bg-primary-300': gridToArray(item).length === gridToArray(value).length,
              'bg-dark-200': gridToArray(item).length !== gridToArray(value).length,
            }"></span>
        </div>
        <div
          class="rounded-small bg-dark-200 hover:bg-dark-400 relative grid h-32 max-w-full cursor-pointer gap-[1px] transition duration-200"
          :style="{ gridTemplateColumns: value }"
          @click="customClick">
          <svg
            class="fill-dark-150 absolute left-1/2 top-1/2 inline-flex h-16 w-16 -translate-x-1/2 -translate-y-1/2"
            viewBox="0 0 512 512">
            <path
              d="M444.788 291.1l42.616 24.599c4.867 2.809 7.126 8.618 5.459 13.985-11.07 35.642-29.97 67.842-54.689 94.586a12.016 12.016 0 0 1-14.832 2.254l-42.584-24.595a191.577 191.577 0 0 1-60.759 35.13v49.182a12.01 12.01 0 0 1-9.377 11.718c-34.956 7.85-72.499 8.256-109.219.007-5.49-1.233-9.403-6.096-9.403-11.723v-49.184a191.555 191.555 0 0 1-60.759-35.13l-42.584 24.595a12.016 12.016 0 0 1-14.832-2.254c-24.718-26.744-43.619-58.944-54.689-94.586-1.667-5.366.592-11.175 5.459-13.985L67.212 291.1a193.48 193.48 0 0 1 0-70.199l-42.616-24.599c-4.867-2.809-7.126-8.618-5.459-13.985 11.07-35.642 29.97-67.842 54.689-94.586a12.016 12.016 0 0 1 14.832-2.254l42.584 24.595a191.577 191.577 0 0 1 60.759-35.13V25.759a12.01 12.01 0 0 1 9.377-11.718c34.956-7.85 72.499-8.256 109.219-.007 5.49 1.233 9.403 6.096 9.403 11.723v49.184a191.555 191.555 0 0 1 60.759 35.13l42.584-24.595a12.016 12.016 0 0 1 14.832 2.254c24.718 26.744 43.619 58.944 54.689 94.586 1.667 5.366-.592 11.175-5.459 13.985L444.788 220.9a193.485 193.485 0 0 1 0 70.2zM336 256c0-44.112-35.888-80-80-80s-80 35.888-80 80 35.888 80 80 80 80-35.888 80-80z"></path>
          </svg>
        </div>
      </div>

      <div v-if="openCustom && !!currentSelectLayout" class="flex w-full flex-col">
        <GridTemplateColumn :columns="currentSelectLayout" @control-change="handleChangeLayout" />
        <div class="mt-auto flex justify-end px-8 pb-8">
          <GButtonV2 size="small" @click="closeCustom">{{ t('Close') }}</GButtonV2>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<style scoped>
.slide-leave-active {
  position: absolute;
}
.slide-leave-active,
.slide-enter-active {
  transition: all 0.3s ease;
}
.slide-enter-from {
  opacity: 0;
  transform: translateY(30px);
}
.slide-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
