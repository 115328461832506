<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useDebounceFn } from '@vueuse/core';
import { GInput, GCheckbox } from '@gem/uikit';
import Wrapper from '../Wrapper/Index.vue';
import type { ProductTagTrigger } from '../type';
import { handleSearch, handleSortable, handleUnique } from '../common';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

const props = withDefaults(
  defineProps<{
    data: ProductTagTrigger;
    productTags?: string[];
    isLoading?: boolean;
    shopDomain: string;
  }>(),
  {},
);

const emit = defineEmits<{
  (e: 'onDelete'): void;
  (event: 'onUpdate', data: ProductTagTrigger): void;
  (event: 'onEdit'): void;
  (event: 'onSearch', value: string): void;
  (event: 'onLoadMore'): void;
}>();

const searchValue = ref('');
const checkedTag = ref<string[]>(props.data.searchTag);
const uniqueTags = ref<string[]>(props.productTags || []);

const onSearch = useDebounceFn((value: string) => {
  searchValue.value = value;
  uniqueTags.value = handleSearch(handleUnique(props.productTags || []), value);
  emit('onSearch', value);
}, 500);

const onSelect = (isChecked: boolean, value: string) => {
  if (!isChecked) {
    checkedTag.value = checkedTag.value.filter((tag) => tag !== value);
  } else {
    checkedTag.value = [...checkedTag.value, value];
  }
  emit('onUpdate', {
    ...props.data,
    searchTag: checkedTag.value,
  });
};

watch(
  () => props.data,
  (newValue) => {
    if (newValue) {
      checkedTag.value = newValue.searchTag;
    }
  },
  {
    immediate: true,
  },
);

const sortProductTags = () => {
  emit('onEdit');
  searchValue.value = '';
  uniqueTags.value = handleSortable(handleUnique(props.productTags || []), checkedTag.value);
};

watch(
  () => props.productTags,
  (val) => {
    if (val) uniqueTags.value = handleUnique(val);
  },
  { immediate: true },
);

const subTitle = computed(() => {
  if (checkedTag.value.length > 0) return checkedTag.value.map((el) => el).join(', ');
  return 'Select tags';
});

const pageUrl = computed(() => `https://${props.shopDomain}/admin/products`);

const isEmptyTags = computed(() => uniqueTags.value.length === 0 && searchValue.value.trim().length === 0);
</script>

<template>
  <Wrapper
    :title="t('Product tags')"
    :sub-title="subTitle"
    :is-loading="isLoading"
    @on-delete="emit('onDelete')"
    @on-open="sortProductTags">
    <template #default="{}">
      <GInput
        :disable="isEmptyTags"
        :value="searchValue"
        input-style="secondary"
        type="text"
        :placeholder="t('Search tag')"
        :classes="{
          'bg-dark-400 !text-dark-100': isEmptyTags,
        }"
        @on-change="onSearch">
        <template #icon>
          <GBaseIcon
            class="text-text-dark-500"
            :class="{
              '!text-dark-100': isEmptyTags,
            }"
            name="search"
            width="16"
            height="16" />
        </template>
      </GInput>
      <div class="max-h-[430px]">
        <div v-if="uniqueTags.length === 0" class="flex h-[228px] flex-col items-center justify-center space-y-12">
          <img alt="" src="../../../assets/empty_icon.png" />
          <p v-if="searchValue.trim().length > 0" class="text-12 text-text-dark-100 text-center">
            {{ t('We couldn’t find any matches for') }}
            <span class="line-clamp-2 text-light-200">"{{ searchValue }}".</span>
            {{ t('Please check your search for any typos or try different keyword.') }}
          </p>
          <p v-else class="text-12 text-text-dark-100 text-center">
            {{ t('We couldn’t find any product tags. Please create on') }}
            <a class="text-primary-250 no-underline" :href="pageUrl" target="_blank">{{ t('Shopify') }}</a>
          </p>
        </div>
        <perfect-scrollbar v-else class="max-h-[316px] max-w-[248px]">
          <GCheckbox
            v-for="tag in uniqueTags"
            :key="tag"
            :text="tag"
            :value="!!checkedTag.includes(tag)"
            class-container="pl-8 !w-fit py-6"
            input-classes="accent-primary-300 flex-shrink-0 relative after:content-[''] after:absolute after:w-full after:h-full after:bg-dark-500 after:border after:border-text-light-300 after:rounded-[2px] checked:after:hidden hover:accent-primary-300"
            label-classes="!text-text-dark-500 max-w-[224px] break-words text-12 leading-5 !pl-12"
            @on-change="(isChecked: boolean) => onSelect(isChecked, tag)" />
        </perfect-scrollbar>
      </div>
    </template>
  </Wrapper>
</template>
