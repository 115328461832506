<script lang="ts" setup>
import { computed } from 'vue';
import Segment from './../Segment.vue';
import Input from './Input.vue';

type CustomContent = {
  prefix?: string;
  suffix?: string;
  unit?: string;
};

type Props = {
  id?: string;
  value?: CustomContent;
  placeholder?: string;
  controlChange?: (id?: string, value?: any) => void;
};

const emit = defineEmits<{
  controlOnChange: [controlId?: string | number, value?: CustomContent];
}>();
const prefix = computed(() => props.value?.prefix?.replaceAll('&nbsp;', ' '));
const suffix = computed(() => props.value?.suffix?.replaceAll('&nbsp;', ' '));

const props = withDefaults(defineProps<Props>(), {});

const controlOnChange = (id: string, value?: string) => {
  if (id) emit('controlOnChange', props.id, { ...props.value, [id]: value });
};

const controlChange = (id: string, value?: string) => {
  if (id) props.controlChange?.(props.id, { ...props.value, [id]: value });
};

const options = [
  {
    label: '%',
    value: 'percentage',
    tooltip: 'Show discount by percentage',
  },
  {
    label: 'Net Discount',
    value: 'price',
    tooltip: 'Show discount by net reduction',
  },
];
</script>

<template>
  <div class="flex flex-col">
    <div class="mb-16 flex">
      <p class="gemx-control-label text-12 text-text-dark-300 flex items-center py-8">{{ t('Type') }}</p>
      <Segment
        id="unit"
        :options="options"
        :value="value?.unit"
        class="max-w-input-horizontal ml-auto w-full"
        @control-change="controlChange" />
    </div>
    <div class="flex">
      <span class="text-12 text-text-dark-300 py-8">{{ t('Content') }}</span>
      <div class="max-w-input-horizontal ml-auto flex w-full items-center gap-[6px]">
        <Input
          control-id="prefix"
          :value="prefix"
          :placeholder="t('SALE')"
          @control-change="controlChange"
          @control-on-change="controlOnChange" />
        <span class="text-text-dark-100 text-12 font-regular">
          {{ value?.unit === 'percentage' ? '%' : '$' }}
        </span>
        <Input
          control-id="suffix"
          :value="suffix"
          :placeholder="t('OFF')"
          @control-change="controlChange"
          @control-on-change="controlOnChange" />
      </div>
    </div>
  </div>
</template>
