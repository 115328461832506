<script lang="ts" setup>
import { useHandleDataSearchComponent } from '../hooks/useHandleDataSearchComponent';
import type { EmitTypeSearchComponent, SearchComponentProps } from '../types';
import { InputText } from '@gem/element-setting-ui';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

defineProps<SearchComponentProps>();
const emit = defineEmits<EmitTypeSearchComponent>();

const { closeSearchSetting, onChangeSearch, inputSearch, onBlurInputSearch, searchKeyword, isSearchSetting } =
  useHandleDataSearchComponent(emit);

defineExpose({
  closeSearchSetting,
  searchKeyword,
  isSearchSetting,
});
</script>

<template>
  <div :class="{ 'w-full': isSearchSetting }" class="px-16" data-test="editor-control-search-setting">
    <div class="w-full items-center">
      <InputText
        ref="inputSearch"
        type="text"
        input-style="secondary"
        :placeholder="t('Search product')"
        :title="t('Search in Settings & Advanced')"
        :value="searchKeyword"
        prefix-icon="polaris-search"
        @blur="onBlurInputSearch"
        @on-change="onChangeSearch" />
    </div>
  </div>
</template>
