<script setup lang="ts">
import type { OptionSelect } from '@gem/uikit';
import { GInput } from '@gem/uikit';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { useInfiniteScroll, watchDebounced, useVirtualList, useDebounceFn } from '@vueuse/core';
import { ref, watch, computed } from 'vue';
import _get from 'lodash/get';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

type StyleOption = {
  name: string;
  value: string;
  id: string;
  icon?: string;
};

const styleOptions: StyleOption[] = [
  {
    name: 'All style',
    value: 'ALL',
    id: 'ALL',
  },
  {
    name: 'Light',
    value: 'LIGHT',
    id: 'LIGHT',
    icon: 'star-light',
  },
  {
    name: 'Regular',
    value: 'REGULAR',
    id: 'REGULAR',
    icon: 'star-regular',
  },
  {
    name: 'Bold',
    value: 'BOLD',
    id: 'BOLD',
    icon: 'star-bold',
  },
  {
    name: 'Filled',
    value: 'FILLED',
    id: 'FILLED',
    icon: 'star-filled',
  },
];

type Props = {
  options: OptionSelect[];
  activeId?: string | number;
  name?: string;
  searchable?: boolean;
  fixWidth?: boolean;
  searchTerm?: string;
  id?: string;
  selectBtnClasses?: string;
  dropdownClasses?: string;
  searchInputClass?: string;
  wrapperClass?: string;
  isLightMode?: boolean;
  disabled?: boolean;
  placeholder?: string;
  displayValue?: string;
  selectedItem?: any;
  searchVal: string;
  loading?: boolean;
  styleIcon?: string;
  //   placement?: Placement;
  //   floating?: boolean;
  //   size?: DropdownSize;
  isSelectNumber?: boolean;
  onSearch?: (value: string) => OptionSelect[];
  delay?: number;
};

const props = withDefaults(defineProps<Props>(), {
  delay: 150,
});

const emit = defineEmits<{
  (e: 'on-scroll'): void;
  (e: 'on-search', value: string): void;
  (e: 'on-select-icon', value: string, id: string): void;
  (e: 'on-select-style', val: string): void;
}>();

const searchValue = ref(props.searchVal);
const filteredOptions = ref(props.options);
const item = ref(props.selectedItem);

const resetScroll = () => {
  if (containerProps.ref.value) {
    containerProps.ref.value.scrollTop = 0;
  }
};

watch(
  () => props.options,
  (newValue) => {
    filteredOptions.value = newValue;
  },
);

watch(
  () => props.searchVal,
  (newValue) => {
    searchValue.value = newValue;
  },
);

watch(
  () => props.selectedItem,
  (newValue) => {
    item.value = newValue;
  },
);

const onSelectOption = (val: string) => {
  emit('on-select-style', val);
  resetScroll();
};

const onSelect = (value: string, id: string) => {
  emit('on-select-icon', value, id);
};

watchDebounced(
  searchValue,
  () => {
    if (typeof props.onSearch === 'function') {
      emit('on-search', searchValue.value);
    } else {
      if (searchValue.value.trim().length > 0 && props.searchable) {
        filteredOptions.value = props.options.filter((option) => {
          return (_get(option, `${props.searchTerm}`) as string)
            .toLowerCase()
            .includes(searchValue.value.trim().toLowerCase());
        });
      } else {
        filteredOptions.value = props.options;
      }
    }
    resetScroll();
  },
  { debounce: 300 },
);

const chunkedOptions = computed(() => {
  const chunkSize = 5;
  const chunks = [];
  for (let i = 0; i < filteredOptions.value.length; i += chunkSize) {
    chunks.push(filteredOptions.value.slice(i, i + chunkSize));
  }
  return chunks;
});

const { list, containerProps, wrapperProps } = useVirtualList(chunkedOptions, {
  itemHeight: 56,
  overscan: 4,
});

const loadMore = useDebounceFn(() => {
  if (props.loading) return;
  emit('on-scroll');
}, 300);

useInfiniteScroll(
  containerProps.ref,
  () => {
    loadMore();
  },
  { distance: 10 },
);
</script>

<template>
  <div>
    <div class="mb-8 flex items-center space-x-8" data-test="editor-modal-icon-filter-bar">
      <Popover v-slot="{ open }" class="relative">
        <PopoverButton as="div">
          <GButtonV2 type="tertiary" size="medium" :active="open" icon-after="chevron-down" icon-view-box="0 0 16 16">
            {{ styleOptions.find((el: StyleOption) => el.id === props.styleIcon)?.name }}
          </GButtonV2>
        </PopoverButton>
        <PopoverPanel
          v-slot="{ close }"
          class="absolute left-0 z-10 mt-4 max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
          <div
            class="rounded-12 bg-dark-300 dropdown-style-icon-picker w-[180px] p-8 transition-all"
            data-test="editor-modal-icon-styles">
            <div
              v-for="(opt, index) in styleOptions"
              :key="index"
              data-test="editor-modal-icon-style-item"
              class="text-12 hover:bg-light-100/20 text-light-100 relative flex h-36 w-full cursor-pointer items-center truncate whitespace-nowrap rounded-xl pl-40 pr-8"
              @click="
                () => {
                  onSelectOption(opt.value);
                  close();
                }
              ">
              <span
                v-if="props.styleIcon?.toLocaleLowerCase() === opt.value.toLocaleLowerCase()"
                class="text-light-450 absolute inset-y-0 left-8 flex items-center">
                <GBaseIcon name="status-check" width="20" height="20" />
              </span>
              <p class="flex w-full items-center justify-between truncate">
                <span class="text-text-dark-500 truncate">{{ opt.name }}</span>
                <g-base-icon
                  v-if="opt.icon"
                  :style="{
                    color: '#AAAAAA',
                  }"
                  :name="opt.icon"
                  height="20"
                  width="20"
                  view-box="0 0 20 20" />
              </p>
            </div>
          </div>
        </PopoverPanel>
      </Popover>
      <GInput
        ref="gInputRef"
        size="small"
        class="px-12"
        input-style="secondary"
        :value="searchValue"
        classes="bg-dark-300 h-36 rounded-xl !pl-[36px] !pr-28"
        container-classes="px-0"
        :searchable="true"
        :placeholder="t('Search icon')"
        data-test="editor-modal-icon-filter-bar-input"
        @on-change="(value: string) => (searchValue = value)">
        <template #icon>
          <div class="ml-16">
            <GBaseIcon class="text-text-dark-300" name="search" width="20" height="20" view-box="0 0 20 20" />
          </div>
        </template>
        <template #clear>
          <button
            v-if="searchValue"
            class="hover:bg-dark-250 absolute right-0 mr-[2px] flex h-28 w-28 items-center justify-center rounded-xl text-center"
            @click.stop="searchValue = ''">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.85355 5.14645C5.65829 4.95118 5.34171 4.95118 5.14645 5.14645C4.95118 5.34171 4.95118 5.65829 5.14645 5.85355L7.29289 8L5.14645 10.1464C4.95118 10.3417 4.95118 10.6583 5.14645 10.8536C5.34171 11.0488 5.65829 11.0488 5.85355 10.8536L8 8.70711L10.1464 10.8536C10.3417 11.0488 10.6583 11.0488 10.8536 10.8536C11.0488 10.6583 11.0488 10.3417 10.8536 10.1464L8.70711 8L10.8536 5.85355C11.0488 5.65829 11.0488 5.34171 10.8536 5.14645C10.6583 4.95118 10.3417 4.95118 10.1464 5.14645L8 7.29289L5.85355 5.14645Z"
                fill="#E2E2E2"></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8Z"
                fill="#E2E2E2"></path>
            </svg>
          </button>
        </template>
      </GInput>
    </div>
    <div class="h-[260px] pt-8">
      <div v-if="filteredOptions.length === 0" class="flex h-full flex-col items-center justify-center space-y-12">
        <img alt="" src="../../assets/empty_icon.png" />
        <p class="text-12 text-text-dark-300 px-32 text-center">
          {{ t('We couldn’t find any matches for') }}
          <span class="line-clamp-2 text-light-200">"{{ searchValue }}".</span>
          {{ t('Please check your search for any typos or try different keyword.') }}
        </p>
      </div>

      <div
        v-bind="containerProps"
        class="list-icon max-h-[240px] w-[calc(100%_+_14px)] overflow-y-auto"
        data-test="editor-modal-icon-listing">
        <div v-bind="wrapperProps" class="wrapper-list flex !w-[calc(100%_-_8px)] flex-col gap-8">
          <div v-for="(items, index) in list" :key="index" class="grid grid-cols-5 gap-8">
            <div
              v-for="(icon, idx) in items.data"
              :key="icon.id"
              :data-test="`editor-modal-icon-${icon.name}`"
              class="bg-dark-300 hover:bg-dark-50 mr-8 flex h-[56px] w-[56px] cursor-pointer items-center justify-center rounded-[6px]"
              :class="{
                'border-primary-300 border': item.id === icon.id,
                'mr-0': idx === items.data.length - 1,
              }"
              @click.stop="onSelect(icon.value, icon.id)">
              <svg viewBox="0 0 256 256" fill="white" xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                <path strokeLinecap="round" strokeLinejoin="round" :d="icon.value" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
@keyframes placeHolderShimmer {
  0% {
    background-position: -150px 0;
  }
  100% {
    background-position: 100px 0;
  }
}
.animated-background {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #494949 10%, #2c2c2c 18%, #494949 33%);
  background-size: 800px 100%;
  position: relative;
}

.dropdown-style-icon-picker {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 8px 16px 2px rgba(0, 0, 0, 0.24);
}

.list-icon::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
}

.list-icon::-webkit-scrollbar-thumb:vertical:hover {
  width: 12px;
  cursor: pointer;
}

.list-icon::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 10px;
  width: 16px;
}

.list-icon::-webkit-scrollbar-thumb {
  min-height: 20px;
}

.list-icon::-webkit-scrollbar-thumb {
  border-radius: 10px;
  @apply bg-text-dark-300;
}
</style>
