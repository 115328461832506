<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <GButtonV2 type="secondary" class="w-full" @click="showManageMenu = true">{{ t('Edit Menu') }}</GButtonV2>
    <!-- <div
      class="text-12 flex h-40 w-full cursor-pointer items-center justify-center font-medium text-white hover:bg-dark-100"
      @click="showManageMenu = true">
      Edit Menu
      <span>
        <svg class="ml-12 fill-light-450" width="7" height="10" viewBox="0 0 7 10" fill="none">
          <path
            d="M1.14766 9.89777L0.352167 9.10227L4.45442 5.00002L0.352168 0.897767L1.14766 0.102272L6.04541 5.00002L1.14766 9.89777Z"
            fill="#E2E2E2"></path>
        </svg>
      </span>
    </div> -->

    <template v-if="showManageMenu">
      <ManageMenu
        :pages="pages"
        :sections="sections"
        @change-keyword="(val) => $emit('change-keyword', val)"
        @load-more="$emit('load-more')"
        @close="showManageMenu = false"
        @save="save" />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, provide, ref } from 'vue';
import type { Data } from '../stores/menu';
import store from '../stores/menu';
import ManageMenu from './menu/ManageMenu.vue';
import type { PageLinkData } from './menu/types';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

provide('store', store);

const emits = defineEmits<{
  (e: 'controlChange', controlId?: string | number, value?: any): void;
  (e: 'change-keyword', input: string): void;
  (e: 'load-more'): void;
  (e: 'save', data: Data): void;
}>();

const props = defineProps<{
  id: string;
  value?: Data;
  pages?: PageLinkData[];
  sections?: PageLinkData[];
}>();

const val = ref(props?.value);
const showManageMenu = ref(false);

onMounted(() => {
  if (val.value) store.actions.setDataMenu(val.value);
});

const change = () => {
  emits('controlChange', props.id, val.value);
};

const save = (data: Data) => {
  val.value = data;
  change();
  emits('save', data);
  store?.actions.setDataMenu(data);
  showManageMenu.value = false;
};
</script>
