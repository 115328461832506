<script lang="ts" setup>
import { cn } from '@gem/common';
import { useI18n } from '@gem/i18n';
import { GButton } from '@gem/uikit-v2';

defineProps<{ isLoading?: boolean; isCollectionType?: boolean }>();
const emit = defineEmits<{
  (e: 'refresh'): void;
}>();
const { t } = useI18n({ useScope: 'global' });
</script>
<template>
  <div
    class="z-5 absolute right-0 hidden h-full items-center justify-end gap-8 pl-[24px] pr-8 group-hover:flex"
    :class="cn({ '!flex': isLoading })"
    :style="{
      background: 'linear-gradient(270deg, #333333 66.5%, rgba(51, 51, 51, 0.8) 85.5%, rgba(51, 51, 51, 0) 100%)',
    }">
    <g-tooltip placement="top" :is-teleport="true" content-class="!shadow-[0px_8px_16px_2px_rgba(0,0,0,0.24)] py-4">
      <GButton
        :loading="isLoading"
        type="ghost"
        size="small"
        only-icon="polaris-refresh"
        @click.stop="emit('refresh')" />
      <template #content>{{ t('Sync product') }}</template>
    </g-tooltip>
    <g-tooltip placement="top" :is-teleport="true" content-class="!shadow-[0px_8px_16px_2px_rgba(0,0,0,0.24)] py-4">
      <GButton type="ghost" size="small" only-icon="polaris-edit" />
      <template #content>{{ t('Edit') }}</template>
    </g-tooltip>
  </div>
</template>
