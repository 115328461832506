<script lang="ts" setup>
import { GButtonV2 } from '@gem/uikit';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

type PropsType = {
  target?: string;
  href: string;
  appName?: string;
  linkType?: 'openApp' | 'install';
  shopifyDomain?: string;
  pageId?: string;
  pageType?: string;
};
const props = withDefaults(defineProps<PropsType>(), {
  target: '_blank',
  href: '#',
});

const emits = defineEmits<(e: 'click') => void>();

const handleClick = () => {
  emits('click');
  window.open(props.href, props.target);
};
</script>

<template>
  <div class="gemx-control">
    <GButtonV2
      v-if="props.linkType === 'openApp'"
      type="secondary"
      size="medium"
      button-width="100%"
      @click="handleClick">
      {{ t('Open app') }}
    </GButtonV2>
    <div v-else-if="props.linkType === 'install'" class="text-text-dark-300 text-12">
      {{ t('You need to') }}
      <span class="text-primary-200 cursor-pointer" @click="handleClick">{{
        t('install { appName }', { appName: props.appName })
      }}</span>
      {{ t('first.') }}
    </div>
  </div>
</template>
