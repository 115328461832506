<script lang="ts" setup>
import ProductModalVue from './modal/ProductModal.vue';
import { ref } from 'vue';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

type Props = {
  id?: string;
  value?: string;
  blogList?: { id?: string; title?: string; image?: string }[];
};
const props = defineProps<Props>();

const blogListVisibility = ref<boolean>(false);
const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string | number, value?: any): void;
  (e: 'controlChange', controlId?: string | number, value?: any): void;
  (e: 'changeVariants', value?: any): void;
  (e: 'controlRefresh'): void;
  (e: 'showmore'): void;
}>();

const val = ref<string>('');

const setValueSelected = (id: string) => {
  val.value = id;
  emit('controlChange', props.id, val.value);
};
</script>
<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <div class="">
      <div class="flex flex-col">
        <input class="bg-dark-400 mb-16 h-32 w-full px-8 text-white outline-none" readonly :value="props.value" />
        <div class="mb-12 flex">
          <button class="rounded-large bg-light-300 h-32 w-[100px]" @click="blogListVisibility = true">
            {{ t('Product List') }}
          </button>
          <button class="rounded-large bg-light-300 ml-auto h-32 px-8" @click="emit('controlRefresh')">
            {{ t('refresh') }}
          </button>
        </div>
      </div>
      <div v-if="blogListVisibility">
        <product-modal-vue
          :product-list="props.blogList"
          :type="'Collection'"
          @showmore="emit('showmore')"
          @refresh="emit('controlRefresh')"
          @close="blogListVisibility = false"
          @set-product-selected="setValueSelected" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
