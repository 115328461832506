<script lang="ts" setup>
import type { CardSelectedProps } from '../type';
import ContentProductSelected from './ContentProductSelected.vue';
import IconSource from './IconSource.vue';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

defineProps<CardSelectedProps>();
defineEmits<{
  (e: 'refresh'): void;
}>();
</script>
<template>
  <div :class="classContainer">
    <img
      v-if="listProduct?.length === 1 && listProduct?.[0].image"
      class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
      :src="listProduct?.[0].image"
      :alt="t('product feature img')" />
    <img
      v-else-if="(listProduct ?? []).length > 1"
      class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
      src="../../images/multi-products.png"
      :alt="t('product feature img')" />
    <img
      v-else
      class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
      src="../../images/collection-thumbnail.png"
      :alt="t('product feature img')" />
    <ContentProductSelected
      :is-show-sub-title="false"
      :title="title"
      :line-clamp-class="{ 'line-clamp-2': listProduct?.length === 1 }" />
    <IconSource :is-loading="isLoading" @refresh="refresh" />
  </div>
</template>
