<template>
  <div
    v-show="statusShow"
    class="shadow-2dp bg-dark-300 popup_insert-link rounded-medium z-layer absolute top-full left-0 w-[240px] py-12 px-8 ring-1 ring-black">
    <h3 class="text-light-450 mb-16 font-semibold">{{ t('Edit Link') }}</h3>
    <form @submit="submit">
      <div class="mb-16">
        <label for="InputUrlInsertLink" class="text-12 text-text-dark-300 mb-4">{{ t('Link to:') }} </label>
        <input
          v-model="valUrl"
          type="url"
          class="rounded-large text-12 box-border w-full cursor-text border border-transparent p-10 text-white outline-none"
          :placeholder="t('Url')" />
        <span class="text-10 text-text-dark-300 mb-4 italic">{{ t('To what URL should this link go?') }}</span>
      </div>
      <div class="mb-16">
        <label for="TextInsertLink" class="text-12 text-text-dark-300 mb-4">{{ t('Text to display:') }} </label>
        <div
          id="TextInsertLink"
          contenteditable="true"
          class="rounded-large text-12 box-border w-full cursor-text border border-transparent p-10 text-white outline-none"
          v-html="valTextUrl" />
      </div>
      <div class="form-group">
        <!-- <Toggler :value="valOpenNewTab" title="Open New Tab (On/Off)" @change="changeOpenNewTabStatus" /> -->
      </div>
      <input
        v-model="valOpenNewTab"
        class="rounded-large text-12 box-border w-full cursor-text border border-transparent p-10 text-white outline-none"
        type="checkbox"
        hidden />
      <div class="flex flex-row justify-between">
        <GButtonV2 type="secondary" :light-mode="true" size="medium" @click="cancelInsertLink">
          {{ t('Cancel') }}
        </GButtonV2>
        <GButtonV2 button-type="submit" type="primary" size="medium">{{ t('Insert') }}</GButtonV2>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

type Props = {
  statusDisplay: boolean;
  valTextInsertLink: string;
  link: string;
  classButton: string;
  isOpenNewTab: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'status-display-popup-insert-link', value: boolean): void;
  (e: 'submit-form-insert-link', data: any): void;
  (e: 'statusDisplayPopupInsertLink', value: boolean): void;
}>();

const statusShow = ref(false);
const valUrl = ref('');
const valTextUrl = ref('');
const valOpenNewTab = ref(false);

const cancelInsertLink = (e: Event) => {
  e.preventDefault();

  statusShow.value = false;
  emit('statusDisplayPopupInsertLink', statusShow.value);
};

const submit = (e: Event) => {
  e.preventDefault();
  valTextUrl.value = document.getElementById('TextInsertLink')?.innerHTML ?? '';
  const data = {
    text: valTextUrl.value,
    url: valUrl.value,
    openNewTab: valOpenNewTab.value,
    type: 'CreateLink',
  };

  emit('submit-form-insert-link', data);
  setStatus(false);
};

const setStatus = (val: boolean) => {
  statusShow.value = val;
  emit('status-display-popup-insert-link', statusShow.value);

  if (statusShow.value == true) {
    window.addEventListener('click', function (event: any) {
      const $parent = event?.target?.closest('.popup_insert-link');
      const $button = event?.target?.closest(props.classButton);
      if (!$parent && !$button) {
        statusShow.value = false;
        emit('statusDisplayPopupInsertLink', false);
      }
    });
  }
};

watch(
  () => props.valTextInsertLink,
  (newVal) => {
    valTextUrl.value = newVal;
  },
);
watch(
  () => props.link,
  (newVal) => {
    valUrl.value = newVal;
  },
);
watch(
  () => props.isOpenNewTab,
  (newVal) => {
    valOpenNewTab.value = newVal;
  },
);
</script>
