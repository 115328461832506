<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import { GRadio } from '@gem/uikit';
import type { PreVariantSelected, Product, ProductVariant } from '../../types';
import { optionsVariantType } from '../constant';
import type { ProductVariantOption } from '../type';
import { GButton } from '@gem/uikit-v2';
import { flattenConnection, getDefaultVariantSelected } from '../../helpers';
import { InputText } from '@gem/element-setting-ui';
import { useI18n } from '@gem/i18n';

const { t } = useI18n({ useScope: 'global' });

const props = defineProps<{
  productSelected: Product;
  productVariantSelect: PreVariantSelected;
}>();

const emit = defineEmits<{
  (e: 'save', value: string): void;
}>();

const searchKeyword = ref('');
const variantSelected = ref<string>('');
const type = ref<string>('all');
const variantOptions = ref<ProductVariantOption[]>([]);

const isDisableSave = computed(() => {
  const prevVariant = props.productVariantSelect?.[props.productSelected?.id]?.variantId ?? '';
  return prevVariant === variantSelected.value;
});

const isShowVariant = computed(
  () => (!!variantOptions.value.length && type.value === 'single') || !!searchKeyword.value.length,
);

const onChangeSearch = (value: string) => {
  searchKeyword.value = value;
  variantOptions.value = variantOptions.value.map((el: ProductVariantOption) => {
    if (el.label.toLowerCase().includes(value.toLowerCase())) {
      return {
        ...el,
        hidden: false,
      };
    }
    return {
      ...el,
      hidden: true,
    };
  });
};

const handleChangeMode = (value: string | number) => {
  type.value = value as string;
  if (value === 'single') {
    const { variantId } = getDefaultVariantSelected(props.productSelected);
    variantSelected.value = variantId;
  } else {
    variantSelected.value = '';
  }
};

const handleChangeVariant = (value: string | number) => {
  variantSelected.value = value as string;
};

const handleSave = () => {
  emit('save', variantSelected.value);
};

watch(
  () => [props.productSelected, props.productVariantSelect],
  ([newProduct, newVariantSelect]) => {
    const initVariant = (newVariantSelect as PreVariantSelected)?.[(newProduct as Product)?.id]?.variantId ?? '';
    variantSelected.value = initVariant;
    if (initVariant) {
      type.value = 'single';
    }
  },
  { immediate: true },
);

watch(
  () => props.productSelected,
  (newProduct) => {
    if (!newProduct) return;

    const variantsFlat = flattenConnection(newProduct.variants) as ProductVariant[];
    const variants = variantsFlat?.sort((a: ProductVariant, b: ProductVariant) => a.position - b.position);

    variantOptions.value = variants
      ?.map((el: ProductVariant) => ({
        ...el,
        label: el.title,
        value: el.id,
        hidden: false,
      }))
      .sort((a: ProductVariantOption) => (props.productVariantSelect?.[newProduct?.id]?.variantId === a.id ? -1 : 1));
  },
  { immediate: true },
);
</script>

<template>
  <div class="max-height-variant flex h-[395px] flex-col gap-16 p-16">
    <div class="w-full items-center">
      <InputText
        type="text"
        input-style="secondary"
        :placeholder="t('Search...')"
        :title="t('Search in Settings & Advanced')"
        :value="searchKeyword"
        prefix-icon="polaris-search"
        @on-change="onChangeSearch" />
    </div>
    <div v-show="searchKeyword.length === 0" class="flex flex-col gap-16">
      <GRadio
        v-for="option in optionsVariantType"
        :key="option.value"
        container-classes="!text-text-dark-500"
        :model-value="type"
        :option="{
          label: option.label,
          value: option.value,
          helpText: '',
        }"
        @update:model-value="handleChangeMode" />
    </div>
    <perfect-scrollbar v-if="isShowVariant" class="-mt-8 pt-8">
      <div class="flex flex-col gap-16" :class="[searchKeyword.length ? 'pl-4' : 'pl-32']">
        <template v-for="option in variantOptions">
          <GRadio
            v-if="!option.hidden"
            :key="option.value"
            container-classes="!text-text-dark-500"
            :model-value="variantSelected"
            :option="{
              label: option.label,
              value: option.value,
              helpText: '',
            }"
            @update:model-value="handleChangeVariant" />
        </template>
      </div>
    </perfect-scrollbar>
    <div
      v-if="variantOptions.filter((el) => !el.hidden).length === 0"
      class="flex h-[280px] max-h-[calc(100vh-200px)] w-full flex-col items-center justify-center">
      <img class="h-[80px] w-[80px] rounded-xl" src="../../../images/searchEmpty.png" alt="collections" />
      <div class="text-12 font-regular text-text-dark-300 text-center leading-5">
        No variant match your search.<br />Try another keyword
      </div>
    </div>
  </div>
  <div class="border-dark-300 border-t p-16">
    <GButton
      type="secondary"
      size="medium"
      class="text-12 font-regular bg-dark-300 relative w-full"
      :disable="isDisableSave"
      @click.stop="handleSave">
      Save
    </GButton>
  </div>
</template>
<style lang="css" scoped>
@media (max-height: 580px) {
  .max-height-variant {
    max-height: calc(100vh - 182px) !important;
  }
}
</style>
