<script setup lang="ts">
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

const emit = defineEmits<{
  (event: 'close'): void;
  (event: 'ok'): void;
}>();
</script>

<template>
  <g-modal
    type="confirm"
    :is-open="true"
    :label-cancel="t('Cancel')"
    label-ok="Delete"
    btn-ok-type="danger"
    :show-btn-close="true"
    footer-class="rounded-b-xxl"
    body-class="h-full p-16 overflow-y-visible border-t-[1px] border-b-[1px] border-light-450"
    header-class="rounded-t-xxl bg-white"
    modal-class="w-[600px] h-full overflow-visible z-50 relative"
    @cancel="emit('close')"
    @close="emit('close')"
    @ok="emit('ok')">
    <template #title>
      <div>
        <p>{{ t('Delete icon') }}</p>
      </div>
    </template>
    <template #default>
      <div class="text-14 text-text-light-500 leading-[24px]">
        {{ t("Are you sure you want to delete this icon? This action can't be undone.") }}
      </div>
    </template>
  </g-modal>
</template>
