<script setup lang="ts">
import { LINK_GUIDELINE, sourcesRelatedTag } from '../constant';
import { useHandleDataRelatedPicker } from '../hooks/useHandleDataRelatedPicker';
import type { RelatedPickerEmit, RelatedPickerProps } from '../type';
import { GBanner } from '@gem/uikit-v2';
import { SettingLayout, Dropdown, TextareaWithAction } from '@gem/element-setting-ui';
import { computed } from 'vue';

const props = defineProps<RelatedPickerProps>();
const emit = defineEmits<RelatedPickerEmit>();
const { isShowNotice, relatedExclude, handleChange, relatedTag, contentRelated } = useHandleDataRelatedPicker(
  emit,
  props,
);

const isShowBanner = computed(
  () =>
    isShowNotice.value && relatedTag.value && (relatedTag.value[0] === 'collection' || relatedTag.value[0] === 'tags'),
);
</script>
<template>
  <div class="flex flex-col gap-16 px-16 pt-4">
    <SettingLayout :label="t('Condition')" :help="{ content: contentRelated.tooltip ?? '' }">
      <template #control>
        <Dropdown
          id="relatedTag"
          :value="relatedTag ? relatedTag[0] : 'collection'"
          :options="sourcesRelatedTag"
          @control-change="(value) =>handleChange('relatedTag', value)" />
      </template>
    </SettingLayout>
    <SettingLayout :label="t('Exclude')">
      <template #control>
        <TextareaWithAction
          id="relatedExclude"
          :value="relatedExclude"
          :min-height="98"
          :placeholder="contentRelated.placeholder"
          @control-change="(value) =>handleChange('relatedExclude', value)" />
      </template>
    </SettingLayout>
    <GBanner
      v-if="isShowBanner"
      type="info"
      message="To get collection handles."
      :link="{ label: `Follow this guideline.`, url: LINK_GUIDELINE }" />
  </div>
</template>
