<script lang="ts" setup>
import { computed } from 'vue';
import {
  POST_PURCHASE_FONT_WEIGHTS,
  POST_PURCHASE_TEXT_TRANSFORM,
  POST_PURCHASE_FONT_SIZE,
  POST_PURCHASE_TEXT_COLOR,
} from './const';
import FieldLabel from '../FieldLabel.vue';
import Select from '../Select.vue';
import Segment from '../Segment.vue';
import type { PostPurchaseTypo } from './type';
import { useI18n } from '@gem/i18n';

const { t } = useI18n({ useScope: 'global' });

type SettingIDType = keyof PostPurchaseTypo;
type Props = {
  id: string;
  value?: PostPurchaseTypo;
  searchQuery?: string;
  hiddenSetting?: SettingIDType[];
};
const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value?: Props['value']): void;
}>();

const fontSize = computed(() => props?.value?.fontSize);
const textColor = computed(() => props?.value?.textColor);
const fontWeight = computed(() => props?.value?.fontWeight);
const textTransform = computed(() => props?.value?.textTransform);

const arrSearchKeyword = computed(() => props.searchQuery?.toLowerCase().split(' ') || []);

const isResultSearch = computed(() => {
  if (!props.searchQuery) return false;
  const settingKeyword = 'styles, font, size, color, font weight, line height, letter spacing, transform'.toLowerCase();
  return arrSearchKeyword.value.findIndex((item) => settingKeyword.includes(item)) > -1;
});

const isShowSettingBySearch = (settingID: String) => {
  if (!isResultSearch.value) return true;
  return (
    isResultSearch.value && arrSearchKeyword.value.findIndex((item) => settingID.toLowerCase().includes(item)) > -1
  );
};

const handleChange = (controlId: string, value?: string) => {
  const dataChange = {
    ...props.value,
    [controlId]: value,
  };
  emit('controlChange', props.id, dataChange as PostPurchaseTypo);
};

const isHiddenSetting = (settingID: SettingIDType) => {
  return props?.hiddenSetting && props?.hiddenSetting.includes(settingID);
};
</script>

<template>
  <div class="w-full">
    <div
      v-if="isShowSettingBySearch('Text Font Size') && !isHiddenSetting('fontSize')"
      class="gemx-control mb-16 flex min-h-[36px] w-full items-center justify-between gap-16">
      <FieldLabel :label="t('Size')" />
      <div class="max-w-input-horizontal w-full">
        <Select
          id="fontSize"
          disable-default
          :options="POST_PURCHASE_FONT_SIZE"
          :value="fontSize"
          @control-change="handleChange" />
      </div>
    </div>
    <div
      v-if="isShowSettingBySearch('Text Color') && !isHiddenSetting('textColor')"
      class="gemx-control mb-16 flex min-h-[36px] w-full items-center justify-between gap-16">
      <FieldLabel :label="t('Color')" />
      <div class="max-w-input-horizontal w-full">
        <Select
          id="textColor"
          disable-default
          :options="POST_PURCHASE_TEXT_COLOR"
          :value="textColor"
          @control-change="handleChange" />
      </div>
    </div>
    <div
      v-if="isShowSettingBySearch('Text Font Weight') && !isHiddenSetting('fontWeight')"
      class="gemx-control mb-16 flex min-h-[36px] w-full items-center justify-between gap-16">
      <FieldLabel :label="t('Font weight')" />
      <div class="max-w-input-horizontal w-full">
        <Select
          id="fontWeight"
          disable-default
          :options="POST_PURCHASE_FONT_WEIGHTS"
          :value="fontWeight"
          @control-change="handleChange" />
      </div>
    </div>
    <div
      v-if="isShowSettingBySearch('Text Transform') && !isHiddenSetting('textTransform')"
      class="gemx-control mb-16 flex min-h-[36px] w-full items-center justify-between gap-16">
      <FieldLabel :label="t('Transform')" />
      <div class="max-w-input-horizontal w-full">
        <Segment
          id="textTransform"
          :value="textTransform"
          :options="POST_PURCHASE_TEXT_TRANSFORM"
          @control-change="handleChange" />
      </div>
    </div>
  </div>
</template>
