<template>
  <div
    ref="dropzone"
    :dropzone-id="dropZoneId"
    class="rounded-medium w-auto transition-all duration-[200ms]"
    :class="{
      'visible h-[42px]': showDropZone,
      'invisible h-0': !showDropZone,
      'pl-40': isChildren,
      'mb-8': showDropZone,
    }">
    <div
      class="text-12 rounded-medium bg-light-500 text-dark-400 flex h-full w-full items-center justify-center font-medium">
      {{ t('Drop item here') }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, ref } from 'vue';
import type { Store } from '../../stores/menu';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

const store = inject<Store>('store');
const props = defineProps<{
  dropZoneId?: string;
}>();

const dropzone = ref<HTMLDivElement>();
const isDragEl = computed(() => store?.getters.getItemDragIsDrag());
const currentX = computed(() => store?.getters.getItemDragX() ?? 0);
const currentY = computed(() => store?.getters.getItemDragY() ?? 0);
const currentHeight = computed(() => store?.getters.getItemDragHeight() ?? 0);
const isChildren = computed(() => store?.getters.getItemDragIsChildren());
const mouseMove = computed(() => store?.getters.getItemDragMouseMove());

const showDropZone = computed(() => {
  if (isDragEl.value && mouseMove.value) {
    const rect = dropzone.value?.getBoundingClientRect();
    const clientY = rect?.y ?? 0;
    const clientX = rect?.x ?? 0;

    if (currentY.value > clientY && currentY.value - currentHeight.value <= clientY) {
      if (currentX.value < clientX) {
        store?.actions.setItemDragIsChildren(false);
      } else {
        store?.actions.setItemDragIsChildren(true);
      }

      store?.actions.setDropZoneId(props.dropZoneId ?? '');
      return true;
    }
  }

  return false;
});
</script>
