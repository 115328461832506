<script setup lang="ts">
import type { OptionSelect } from '@gem/uikit';
import IconUploadEmpty from './IconUploadEmpty.vue';
import IconUploadList from './IconUploadList.vue';
import { ref } from 'vue';

type Props = {
  id?: string | number;
  data: OptionSelect[];
  allowedFiles?: string[];
  maximumSize?: number;
  shopId: string;
  loading?: boolean;
  selectedItem: string;
};

const props = withDefaults(defineProps<Props>(), {
  allowedFiles: () => ['image/svg+xml'],
  maximumSize: 100 * 1024,
});

const enterTarget = ref<EventTarget | null>(null);
const wrapperRef = ref<HTMLInputElement | null>(null);
const wrapperRefSub = ref<HTMLInputElement | null>(null);

const emit = defineEmits<{
  (e: 'on-scroll'): void;
  (e: 'on-error', type: 'error' | 'success', msg: string): void;
  (e: 'upload-image', formData: FormData): void;
  (e: 'on-select-icon', value: string, id: string): void;
}>();

const onScroll = () => {
  emit('on-scroll');
};

const postUploadImage = (urlImage: File) => {
  const formData = new FormData();
  formData.append('file', urlImage);
  formData.append('fileType', 'ICON');
  formData.append('shopID', props.shopId);
  formData.append('appName', 'gemx');
  emit('upload-image', formData);
};

const handleChangeFileFromUploader = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (!target.files?.length) return;
  const file = target.files.item(0);
  if (!file) return;
  if (!props.allowedFiles.includes(file.type)) {
    emit('on-error', 'error', 'Supported format: SVG');
    clearInputFile();
    return;
  }
  if (file.size > props.maximumSize) {
    emit('on-error', 'error', 'File size limit: 100KB');
    clearInputFile();
    return;
  }
  postUploadImage(file);
  clearInputFile();
};
const clearInputFile = () => {
  if (wrapperRef.value?.value) {
    wrapperRef.value.value = '';
  }
  if (wrapperRefSub.value?.value) {
    wrapperRefSub.value.value = '';
  }
};

const onDragEnter = (e: Event) => {
  enterTarget.value = e.target;
  wrapperRef.value?.classList?.add('block');
  wrapperRef.value?.classList?.remove('hidden');
};

const onDragLeave = (e: Event) => {
  if (enterTarget.value === e.target) {
    wrapperRef.value?.classList.add('hidden');
    wrapperRef.value?.classList.remove('block');
  }
};

const onDrop = () => {
  wrapperRef.value?.classList.add('hidden');
  wrapperRef.value?.classList.remove('block');
};

const onSelect = (value: string, id: string) => {
  emit('on-select-icon', value, id);
};
</script>

<template>
  <div class="relative flex h-[341px] flex-col" @dragenter="onDragEnter" @drop="onDrop" @dragleave="onDragLeave">
    <input
      ref="wrapperRef"
      type="file"
      accept="image/*"
      class="z-2 absolute top-0 left-0 z-10 hidden h-full w-full cursor-pointer opacity-0"
      @change="handleChangeFileFromUploader" />
    <div class="grow-1 relative max-h-[273px]">
      <IconUploadEmpty v-if="props.data.length === 0" />
      <IconUploadList
        v-if="props.data.length > 0"
        :id="props.id"
        :loading="props.loading"
        :data="data"
        :selected-item="selectedItem"
        @on-select-icon="onSelect"
        @on-scroll="onScroll" />
    </div>
    <div class="border-dark-250 relative -mx-16 mt-auto max-h-[68px] border-t p-16">
      <GButtonV2 type="secondary" size="medium" class="text-12 font-regular relative w-full">
        <input
          ref="wrapperRefSub"
          type="file"
          accept="image/svg+xml"
          class="absolute top-0 left-0 h-full w-full cursor-pointer opacity-0"
          @change="handleChangeFileFromUploader" />
        <g-base-icon class="mr-8" name="plus" width="16" height="16" view-box="0 0 16 16" />
        {{ t('Upload .SVG icon') }}
      </GButtonV2>
    </div>
  </div>
</template>
