<script setup lang="ts">
import { GPopoverSidebar } from '@gem/uikit';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue';
import { computed, ref } from 'vue';
import type { FontItem, TypographyProps } from './types';
import { capitalizeFirstLetter } from './types';
import TypographyFormEdit from './TypographyFormEdit.vue';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

type Props = {
  mobileOnly?: boolean;
  value?: Record<string, TypographyProps>;
  hasChange?: boolean;
  overlayContainer?: string;
  title?: string;
  devices?: string[];
  font?: Record<string, FontItem>;
};

const props = withDefaults(defineProps<Props>(), {
  overlayContainer: 'body',
});

const emit = defineEmits<{
  (e: 'change', device: string, name: string, value: any): void;
  (e: 'onChange', device: string, name: string, value: any): void;
  (e: 'changeScreen', screenId: string): void;
  (e: 'saveData'): void;
  (e: 'close'): void;
  (e: 'reset'): void;
  (e: 'onClickSubAction', type: string, value?: any): void;
}>();

const resetKey = ref(0);

const extendValue = computed(() => {
  return Object.fromEntries(
    props.devices?.map((device, index) => {
      const composeDeviceValue = props.devices?.slice(0, index + 1).reduce((curr, deviceId) => {
        const controlDevice = props.value?.[deviceId];
        return {
          ...curr,
          ...controlDevice,
        };
      }, {} as TypographyProps);
      return [device, composeDeviceValue];
    }) ?? [],
  );
});

const getFontWeight = (device: string) => {
  const ff = extendValue?.value?.[device]?.fontFamily;
  const font = ff ? props.font?.[ff] : undefined;

  return font?.variants.map((item) => {
    const value = ['italic', 'regular'].includes(item) ? '400' : item.replace('italic', '');

    return {
      label: value,
      value: value,
    };
  });
};

const fontWeight = computed<{ [key: string]: { label: string; value: string }[] | undefined }>(() => {
  return {
    desktop: getFontWeight('desktop'),
    tablet: getFontWeight('tablet'),
    mobile: getFontWeight('mobile'),
  };
});

const fontFamily = computed(() => {
  return Object.entries(props.font || {}).map(([fontKey, fontValue]) => ({
    label: capitalizeFirstLetter(fontKey),
    value: fontKey,
    metaData: fontValue.family,
  }));
});

const close = () => {
  emit('close');
};

const change = (device: string, name: string, value?: string) => {
  emit('change', device, name, value);
};
const onChange = (device: string, name: string, value?: string) => {
  emit('onChange', device, name, value);
};

function changeTab(index: number) {
  const screenId = props.devices?.[index];
  if (screenId) {
    emit('changeScreen', screenId);
  }
}

function onClickSubAction(type: string, value?: any) {
  emit('onClickSubAction', type, value);
}
</script>

<template>
  <GPopoverSidebar
    :closeable="true"
    arrow-class="text-dark-300"
    :overlay-container="overlayContainer"
    :overlay="true"
    @close="close">
    <template #default="{}">
      <button
        ref="btn"
        class="bg-dark-400 text-text-dark-300 hover:text-light-450 hover:bg-dark-200 flex aspect-square h-full shrink-0 items-center justify-center rounded-xl transition-colors duration-200">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.63388 9.11612C5.12204 9.60427 5.12204 10.3957 4.63388 10.8839C4.14573 11.372 3.35427 11.372 2.86612 10.8839C2.37796 10.3957 2.37796 9.60427 2.86612 9.11612C3.35427 8.62796 4.14573 8.62796 4.63388 9.11612Z"
            fill="#E2E2E2" />
          <path
            d="M17.1339 9.11612C17.622 9.60427 17.622 10.3957 17.1339 10.8839C16.6457 11.372 15.8543 11.372 15.3661 10.8839C14.878 10.3957 14.878 9.60427 15.3661 9.11612C15.8543 8.62796 16.6457 8.62796 17.1339 9.11612Z"
            fill="#E2E2E2" />
          <path
            d="M10.8839 10.8839C11.372 10.3957 11.372 9.60427 10.8839 9.11612C10.3957 8.62796 9.60427 8.62796 9.11612 9.11612C8.62796 9.60427 8.62796 10.3957 9.11612 10.8839C9.60427 11.372 10.3957 11.372 10.8839 10.8839Z"
            fill="#E2E2E2" />
        </svg>
      </button>
    </template>
    <template #content="{ closePopup }">
      <div ref="popup" class="bg-dark-300 shadow-4dp w-screen max-w-[264px] transform px-4 sm:px-0">
        <div class="rounded-small">
          <div class="border-dark-200 flex h-40 items-center justify-between border-b px-8">
            <h3 class="text-14 text-light-450 font-semibold">{{ title }}</h3>
            <div
              class="hover:bg-light-100 rounded-medium flex aspect-square w-24 cursor-pointer items-center justify-center hover:bg-opacity-20"
              @click="closePopup">
              <g-base-icon name="close" width="16" height="16" class="text-light-450"></g-base-icon>
            </div>
          </div>
          <div v-if="mobileOnly" class="pt-16">
            <TypographyFormEdit
              :value="extendValue?.mobile"
              :reset-key="resetKey"
              :font-family="fontFamily"
              :font-weight="fontWeight.mobile"
              @change="(...args) => change('mobile', ...args)"
              @on-change="(...args) => onChange('mobile', ...args)" />
          </div>
          <TabGroup v-else @change="changeTab">
            <TabList class="rounded-small relative mx-8 flex items-center gap-16">
              <Tab v-for="device in devices" :key="device" v-slot="{ selected }" as="template">
                <button
                  :class="{ '!border-primary-300 text-light-450': selected, 'text-text-dark-300': !selected }"
                  class="text-12 z-5 border-b-2 border-transparent py-8 text-center capitalize focus-visible:outline-none">
                  {{ device }}
                </button>
              </Tab>
              <div class="border-light-500 absolute bottom-0 left-0 z-0 w-full border-b border-opacity-20"></div>
            </TabList>
            <TabPanels class="p-8">
              <TabPanel v-for="device in devices" :key="device">
                <TypographyFormEdit
                  :value="extendValue?.[device]"
                  :reset-key="resetKey"
                  :font-family="fontFamily"
                  :font-weight="fontWeight[device]"
                  @change="(...args) => change(device, ...args)"
                  @on-change="(...args) => onChange(device, ...args)"
                  @on-click-sub-action="onClickSubAction" />
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
        <div v-if="hasChange && !mobileOnly" class="mx-auto flex items-center justify-center gap-8 px-0 pb-16">
          <GButtonV2 type="secondary" size="medium" :light-mode="true" @click="$emit('saveData')">
            {{ t('Update to Global Style') }}
          </GButtonV2>
          <GButtonV2 type="primary" size="medium" @click="closePopup"> {{ t('Save & Close') }} </GButtonV2>
        </div>
      </div>
    </template>
  </GPopoverSidebar>
</template>
