<script lang="ts" setup>
import { GButton } from '@gem/uikit-v2';

import { replaceImageToSize } from '../../../../hook/useResizeImage';
import ArchivedStatus from '../../../../icons/ArchivedStatus.vue';
import DraftStatus from '../../../../icons/DraftStatus.vue';
import { useHandleDataAssigned } from '../hooks/useHandleDataAssigned';
import type { EmitTypeProductSingle, SingleProductProps } from '../types';
import SkeletonProductList from '../../product-list-picker-modal/components/SkeletonProductList.vue';
import { computed } from 'vue';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

// props & emit
const props = defineProps<SingleProductProps>();
const emit = defineEmits<EmitTypeProductSingle>();
const isLoadingAssigned = computed(() => props.isLoading);
const heightSkeleton = computed(() => (props.isNotHaveProductInStore || props.isNotHaveAssigned ? '326px' : '280px'));
const { setValueSelected, productInfinityScroll } = useHandleDataAssigned(emit);
const isShowNoProduct = computed(() => (props.list ?? []).length === 0 && !props.isLoading);
</script>
<template>
  <div
    v-if="isShowNoProduct"
    class="flex h-[282px] max-h-[calc(100vh-298px)] w-full flex-col items-center justify-center"
    :class="{
      '!h-[326px]': isNotHaveProductInStore || isNotHaveAssigned,
    }">
    <img class="h-[80px] w-[80px] rounded-xl" src="../../../images/searchEmpty.png" :alt="t('collections')" />
    <div v-if="isNotHaveProductInStore" class="text-12 font-regular text-text-dark-300 text-center leading-5">
      {{ t('No product found in your store.') }}<br />
      <a class="text-primary-200 cursor-pointer no-underline" type="link" @click="navigateToProductShopify"
        >{{ t('Create new in Shopify') }}
      </a>
      {{ t('and try again.') }}
    </div>
    <div v-else-if="isNotHaveAssigned" class="text-12 font-regular text-text-dark-300 text-center leading-5">
      {{ t('No product assigned.') }}<br />
      <a class="text-primary-200 cursor-pointer no-underline" type="link" @click="emit('openAssignModal')"
        >t('Assign product')</a
      >
      {{ t('and try again.') }}
    </div>
    <div v-else class="text-12 font-regular text-text-dark-300 text-center leading-5">
      {{ t('No product match your search.') }}<br />{{ t('Try another keyword') }}
    </div>
  </div>
  <perfect-scrollbar v-else ref="productInfinityScroll" class="max-height-list-single !h-[282px] !max-h-[282px]">
    <SkeletonProductList v-if="isLoadingAssigned" :items-length="5" :height="heightSkeleton" />
    <div v-else class="flex flex-col px-16">
      <div
        v-for="(item, index) in list"
        :key="index"
        data-test="setting-modal-product-item"
        class="active:border-primary-300 hover:bg-dark-300 flex cursor-pointer items-center gap-8 rounded-[12px] border border-transparent p-8"
        :class="{
          'border-primary-300 !cursor-default border': item.id == value?.id,
        }"
        @click="setValueSelected(item.id)"
        @keypress="setValueSelected(item.id)">
        <div
          class="h-[40px] w-[40px] flex-shrink-0 rounded-xl bg-transparent"
          :class="{
            'flex items-center justify-center': !item.image,
          }">
          <img
            v-if="item.image"
            class="h-full w-full rounded-xl object-cover"
            :src="replaceImageToSize(item?.image ?? '', '120x120') ?? item.image"
            :alt="t('collections')" />
          <img v-else class="h-full w-full rounded-xl" src="../../../images/no-product.png" :alt="t('collections')" />
        </div>
        <div class="text-12 line-clamp-2 text-text-dark-500 font-regular w-full truncate whitespace-pre-wrap leading-5">
          {{ item?.title }}
        </div>
        <div v-if="item.status?.toLowerCase() !== 'active'" class="flex items-center">
          <g-tooltip
            v-if="item?.status?.toLowerCase() === 'draft'"
            placement="top"
            wrapper-class="w-full"
            content-class="!whitespace-normal"
            :is-teleport="true">
            <DraftStatus />
            <template #content>
              <div class="text-text-dark-500 font-regular text-12 leading-5">{{ t('Draft product') }}</div>
            </template>
          </g-tooltip>
          <g-tooltip
            v-if="item?.status?.toLowerCase() === 'archived'"
            placement="top"
            wrapper-class="w-full"
            content-class="!whitespace-normal"
            :is-teleport="true">
            <ArchivedStatus />
            <template #content>
              <div class="text-text-dark-500 font-regular text-12 leading-5">{{ t('Archived product') }}</div>
            </template>
          </g-tooltip>
        </div>
      </div>
      <GButton v-if="isLoadMore" :loading="isLoadMore" size="medium" type="ghost" only-icon="polaris-refresh" />
    </div>
  </perfect-scrollbar>
</template>

<style lang="css" scoped>
@media (max-height: 580px) {
  .max-height-list-single {
    max-height: calc(100vh - 298px) !important;
  }
}

.max-height-list-single {
  max-height: 282px;
}
</style>
