<script lang="ts" setup>
import type { ColorType } from '../../global-color/types';

import { computed, ref, watch } from 'vue';
import { useDebounceFn } from '@vueuse/core';

import { GButtonV2 } from '@gem/uikit';
import { Color } from '../Color';
import { lightenDarkenColor } from '../../../utils/lightenDarkenColor';
import { TRANSPARENT_COLOR } from '../helpers';
import type { THEME_DARK } from '../const';
import { THEME_LIGHT } from '../const';
import { isValidHexWithoutPrefix, isValidColorFormat, getHexColorThreeDigit } from '../../../helpers/regex';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

const props = defineProps<{
  theme: typeof THEME_DARK | typeof THEME_LIGHT;
  value?: ColorType | string;
  showTransparentColor: boolean;
  openColorPicker: boolean;
}>();

const emits = defineEmits<{
  (e: 'toggle-color-picker'): void;
  (e: 'change-color', color: ColorType | string): void;
}>();

const isInputFocus = ref(false);
const isInputHover = ref(false);

const color = ref(Color.fromString(props.value));
watch(
  () => props.value,
  (newValue) => {
    color.value = Color.fromString(newValue);

    if (getHexColorThreeDigit(noHashtagHexColor.value)) {
      return;
    }

    changeTagHexColor();
  },
);

const noHashtagHexColor = ref(
  props.value && props.value !== TRANSPARENT_COLOR ? color.value.getHexColor()?.replace('#', '') : '',
);
const disableShortcutDelete = (e: KeyboardEvent) => {
  if (e.key === 'Delete' || e.key === 'Backspace') {
    e.stopPropagation();
  }
};
const selectAllFocus = (e: FocusEvent) => {
  isInputFocus.value = true;
  (e.target as HTMLInputElement).select();
};
const stopPropagation = (e: Event) => e.stopPropagation();
const changeTagHexColor = () => {
  noHashtagHexColor.value = color.value?.getHexColor()?.replace('#', '');
};
const changeColor = () => {
  changeTagHexColor();
  emits('change-color', color.value.getColor());
};
const changeColorOnThreeDigitHex = (value: string) => {
  noHashtagHexColor.value = value;
  color.value = Color.fromString(value);
  emits('change-color', color.value.getColor());
};
const debouncedChangeHexValue = useDebounceFn((e: Event) => {
  const value = (e.target as HTMLInputElement).value;
  if (!value) return;

  if (isValidHexWithoutPrefix(value)) {
    const opacity = color.value.a;
    color.value = Color.fromString('#' + value);
    color.value.setAlpha(opacity);
    changeColor();
    return;
  }

  if (isValidColorFormat(value)) {
    color.value = Color.fromString(value);
    changeColor();
  }

  if (getHexColorThreeDigit(value)) {
    changeColorOnThreeDigitHex(value);
  }
}, 300);
const debouncedChangeAlphaValue = useDebounceFn((e: Event) => {
  const inputVal = Number((e.target as HTMLInputElement).value);
  const value = Number((Math.max(0, Math.min(100, inputVal)) / 100).toFixed(2));
  if (value >= 0 && value <= 1) {
    color.value.setAlpha(value);
    changeColor();
  }
}, 500);

function onBlurHexInput(e: Event) {
  isInputFocus.value = false;
  const value = (e.target as HTMLInputElement).value;
  const validValue = getHexColorThreeDigit(value);
  if (validValue) {
    color.value = Color.fromString(validValue);
    changeColor();
  }
}

const browserSupportEyeDropper = computed(() => window?.EyeDropper);
declare global {
  interface Window {
    EyeDropper: any;
  }
}

interface EyeDropperConstructor {
  new (): EyeDropperInterface;
}

interface ColorSelectionOptions {
  signal: AbortSignal;
}

interface ColorSelectionResult {
  sRGBHex: string;
}

interface EyeDropperInterface extends EyeDropperConstructor {
  open: (options?: ColorSelectionOptions) => Promise<ColorSelectionResult>;
}
declare let EyeDropper: {
  prototype: EyeDropperInterface;
  new (): EyeDropperInterface;
};
const onOpenEyeDropper = () => {
  const eyeDropper = new EyeDropper();
  eyeDropper
    .open()
    .then((result: any) => {
      color.value = Color.fromString(result.sRGBHex);
      changeColor();
    })
    .catch((e: any) => {
      console.error(e);
    });
};
</script>

<template>
  <div class="flex gap-8">
    <GButtonV2
      :type="theme === THEME_LIGHT ? 'grey' : 'tertiary'"
      size="medium"
      class="h-36 !w-36 rounded-xl !p-0"
      @click="emits('toggle-color-picker')">
      <div
        class="content !h-20 !w-20"
        :class="{
          'hover:border-light-450': showTransparentColor || color.a === 0,
        }">
        <div v-if="!showTransparentColor && color.a !== 0" class="relative h-20 w-20">
          <div
            class="picked-color !h-20 !w-20 rounded-full border"
            :style="{
              backgroundColor: value,
              'border-color': lightenDarkenColor(color.getColor()),
            }" />
        </div>
        <img
          v-else
          src="../../../assets/color-transparent-preview.png"
          class="h-20 w-20"
          alt="color-transparent-preview" />
      </div>
    </GButtonV2>
    <div
      class="relative flex flex-1"
      @mouseover="() => (isInputHover = true)"
      @mouseleave="() => (isInputHover = false)">
      <input
        :value="noHashtagHexColor"
        type="text"
        :placeholder="t('E.g FFFFFF')"
        class="text-12 bg-dark-300 border-dark-300 text-light-200 placeholder-text-dark-300 focus:border-primary-300 h-[36px] rounded-xl rounded-r-none border pl-8 outline-none"
        :class="{
          'w-full': !openColorPicker,
          'w-[calc(100%-48px)]': openColorPicker,
          '!border-primary-300': isInputFocus || isInputHover,
          '!bg-light-300 border-light-300 !text-dark-400': theme === THEME_LIGHT,
        }"
        @input="debouncedChangeHexValue"
        @keydown="disableShortcutDelete"
        @mousedown="stopPropagation"
        @focus="selectAllFocus"
        @blur="onBlurHexInput" />
      <input
        :value="Math.round(color.a * 100)"
        type="number"
        min="0"
        max="100"
        step="1"
        :class="{
          '!border-primary-300': isInputFocus || isInputHover,
          '!bg-light-300 border-light-300 !text-dark-400': theme === THEME_LIGHT,
        }"
        class="hide-input-number-btn text-12 bg-dark-300 border-dark-300 text-light-200 focus:border-primary-300 peer h-[36px] w-[48px] rounded-xl rounded-l-none border border-l-0 pr-[18px] pl-8 outline-none"
        @input="debouncedChangeAlphaValue"
        @keydown="disableShortcutDelete"
        @focus="selectAllFocus"
        @blur="() => (isInputFocus = false)" />
      <span
        class="text-12 text-light-200 absolute right-8 top-[9px]"
        :class="{
          '!text-dark-400': theme === THEME_LIGHT,
        }"
        >%</span
      >
    </div>
    <GButtonV2
      v-if="browserSupportEyeDropper"
      :type="theme === THEME_LIGHT ? 'grey' : 'tertiary'"
      size="medium"
      class="w-36 rounded-xl p-8"
      @click.stop="onOpenEyeDropper">
      <GBaseIcon name="eye-dropper" width="20" height="20" viewBox="0 0 16 16" />
    </GButtonV2>
  </div>
</template>

<style scoped>
.hide-input-number-btn::-webkit-outer-spin-button,
.hide-input-number-btn::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hide-input-number-btn {
  -moz-appearance: textfield;
}
</style>
