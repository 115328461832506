<script lang="ts" setup>
import { ref, watch } from 'vue';
import InputNumber from './InputNumber.vue';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

type Props = {
  id?: string;
  value?: any;
  max?: number;
  min?: number;
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string | number, value?: any): void;
  (e: 'controlChange', controlId?: string | number, value?: any): void;
  (e: 'controlFocus', controlId?: string | number, value?: any): void;
  (e: 'controlBlur', controlId?: string | number, value?: any): void;
}>();

const deg = ref<number | undefined>(props.value);
const maxV = ref<number>(props.max !== undefined && props.max > 359 ? 359 : props.max ?? 359);
const minV = ref<number>(props.min !== undefined && props.min < 0 ? 0 : props.min ?? 0);
const centerPoint = ref<HTMLElement>();

watch(
  () => props.value,
  (value) => {
    if (value !== undefined) {
      if (value > maxV.value) deg.value = maxV.value;
      if (value < minV.value) deg.value = minV.value;
      deg.value = value;
    }
  },
);

const rotateHandler = (event: MouseEvent) => {
  if (!centerPoint.value?.getClientRects()) return;
  const { x, y } = centerPoint.value.getClientRects()[0];
  const mousemove = (e: MouseEvent) => {
    const { x: cX, y: cY } = e;
    const pX = cX - x;
    const pY = cY - y;
    const d = Math.sqrt(pX * pX + pY * pY);
    onChange();
    clearSelection();
    let currentDeg = Math.round(Math.asin(pY / d) * (180 / Math.PI));
    if (pX < 0) currentDeg = 180 - currentDeg;
    if (currentDeg < 0) {
      currentDeg = maxV.value + currentDeg;
    }
    deg.value = currentDeg;
  };
  mousemove(event);
  const mouseup = () => {
    window.removeEventListener('mousemove', mousemove);
    window.removeEventListener('mouseup', mouseup);
    change();
  };

  window.addEventListener('mousemove', mousemove);
  window.addEventListener('mouseup', mouseup);
};
const clearSelection = () => {
  if (window.getSelection) {
    if (window.getSelection()?.empty) {
      // Chrome
      window.getSelection()?.empty();
    } else if (window.getSelection()?.removeAllRanges) {
      // Firefox
      window.getSelection()?.removeAllRanges();
    }
  } else if (document.getSelection()) {
    // IE?
    document.getSelection()?.empty();
  }
};

const onChange = () => {
  emit('controlOnChange', props.id, deg.value);
};
const change = () => {
  if (deg.value) {
    if (deg.value > maxV.value) deg.value = maxV.value;
    if (deg.value < minV.value) deg.value = maxV.value;
    emit('controlOnChange', props.id, deg.value);
  }
  emit('controlChange', props.id, deg.value);
};
const inputChange = (_id?: string | number, value?: number) => {
  emit('controlChange', props.id, value);
};
const inputOnChange = (_id?: string | number, value?: number) => {
  emit('controlOnChange', props.id, value);
};
</script>
<template>
  <div class="gemx-control w-full">
    <slot name="label"></slot>
    <div class="gfSABody justify-between">
      <div class="gfSALeft">
        <div class="centered">
          <div class="gfSACenter" @mousedown="rotateHandler">
            <div ref="centerPoint" class="gfSACenterCenter" />
            <div ref="clockwise" class="gfSACenterBorder" :style="`transform: rotate(${deg}deg)`">
              <span />
            </div>
          </div>
        </div>
      </div>
      <div class="gfSARight">
        <div class="gt_control gt_control-input-number">
          <div class="rounded-medium flex items-center border border-transparent">
            <div class="w-[76px]">
              <InputNumber
                id="deg"
                input-class="border-none"
                :value="deg"
                :max="maxV"
                :min="minV"
                @control-change="inputChange"
                @control-on-change="inputOnChange">
              </InputNumber>
            </div>
            <div class="flex h-32 w-32 items-center justify-center border-l border-transparent">
              <span class="text-text-dark-300 text-12 font-medium">{{ t('deg') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$blue: #839bc0;
$text: #333333;
$textShade2: #7f7f7f;
$textShade3: #dadada;

.gfSABody {
  width: 100%;
  margin-top: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  .gfSALeft {
    width: 40%;
    float: left;

    .centered {
      width: 60px;
      float: left;
      height: 60px;

      svg {
        display: block;
        margin: auto;
        cursor: pointer;
        padding: 3px;
        width: 14px;
        height: 14px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
      }

      svg:hover {
        path {
          fill: #ccc !important;
        }
      }

      .gfSACenter {
        width: calc(100% - 1px);
        height: calc(100% - 1px);
        float: left;
        border-radius: 50%;
        border: solid 1px rgba(177, 177, 177, 0.65);
        position: relative;
        cursor: pointer;
        background: #eff1f2;

        .gfSACenterCenter {
          width: 6px;
          height: 6px;
          background-color: transparent;
          border-radius: 50%;
          margin: auto;
          margin-top: calc(50% - 3px);
        }

        .gfSACenterBorder {
          position: absolute;
          top: calc(50% - 6px);
          width: 50%;
          left: 50%;
          -webkit-transform-origin: left;
          transform-origin: left;
          padding: 6px 0px;
          cursor: pointer;

          span {
            content: '';
            width: 8px;
            height: 8px;
            background-color: #4f46ba;
            position: absolute;
            border-radius: 100%;
            top: 2px;
            right: 6px;
          }
        }
      }
    }
  }

  .gfSARight {
    display: flex;

    input {
      max-width: 100%;
      min-width: 62px;
      width: fit-content;
      border: none;
      color: #7f7f7f;
      float: left;
      text-align: left;
      background-color: transparent;
      padding: 8px 5px;
      font-size: 14px;
    }
  }

  .gt_control-input-number {
    ::-webkit-input-placeholder,
    ::-moz-placeholder,
    :-moz-placeholder,
    :-ms-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $textShade2;
    }

    &:hover {
      input[type='number'] {
        transition: all 0.3s;
        border-color: #3e50af;
      }
    }

    input[type='number'] {
      border: none;
      border: solid 1px #c5cbd7;
      border-radius: 3px;
      height: 32px;
      padding: 6px 0 5px 8px;
      width: 100%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: -webkit-all 0.3s;
      transition: -webkit-all 0.3s;
      transition: all 0.3s;
      transition: all 0.3s, -webkit-all 0.3s;
      font-size: 14px;
      line-height: 21px;
      outline: none;

      &:focus {
        border-color: #3e50af;
      }

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
    }

    input[disabled] {
      border: none;
      background-color: #f0f3f6;
      color: #c5cbd7;

      &:hover {
        cursor: not-allowed;
      }
    }

    button[disabled] {
      border: none;
      background-color: #f0f3f6;

      svg path {
        fill: #c5cbd7;
      }

      &:hover {
        cursor: not-allowed;
        background-color: #f0f3f6;

        svg path {
          fill: #c5cbd7;
        }
      }
    }
  }
}
</style>
