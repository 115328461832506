<script lang="ts" setup>
import { GModal, GCollapseTransition } from '@gem/uikit';
import type { Ref } from 'vue';
import { ref, watch } from 'vue';
import type { File } from '../type/graphql';
import SwatchesColor from './swatches/SwatchesColor.vue';
import SwatchesImage from './swatches/SwatchesImage.vue';
import type { GlobalSwatchesData, SwatchesOptionType } from '@gem/common';
import SkeletonManagerSwatches from '../component/SkeletonManagerSwatches.vue';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'uploadImage', formData: FormData, index?: string, toVariant?: string): void;
  (e: 'addItem', data: GlobalSwatchesData[]): void;
  (e: 'save'): void;
  (e: 'fetchNextPage'): void;
  (e: 'changeData', value: GlobalSwatchesData[]): void;
  (e: 'handleAlert', type: string, msg: string, time?: number): void;
}>();

const props = withDefaults(
  defineProps<{
    shopId: string;
    optionType: SwatchesOptionType;
    optionTitle: string;
    showOther: boolean;
    imageUploading: boolean;
    shopifyPlan?: string;
    data?: GlobalSwatchesData[];
    index: number;
    images?: File[];
    maximumSize?: number;
    allowedFiles?: string[];
    isInstant?: boolean;
    isLoading?: boolean | Ref<boolean>;
  }>(),
  {
    data: () => [],
    maximumSize: 10 * 1024 * 1024, // 10MB
    allowedFiles: () => ['image/jpeg', 'image/gif', 'image/png', 'image/webp', 'image/svg+xml'],
    showOther: false,
    imageUploading: false,
    isLoading: true,
  },
);
const dataClone = ref<GlobalSwatchesData[]>(props.data);
const isDataDiff = ref<boolean>(false);
const saveClicked = ref<boolean>(false);
const dataOriginal: GlobalSwatchesData[] = JSON.parse(JSON.stringify(dataClone.value));
const isColorNotSet = ref<boolean>(false);
const isImageNotSet = ref<boolean>(false);

const handleInitComponent = (optionType: SwatchesOptionType) => {
  switch (optionType) {
    case 'color':
      return SwatchesColor;
    case 'image':
      return SwatchesImage;
  }
};

const handleUploadImage = (formData: FormData, index: string, parentIndex: string) => {
  isDataDiff.value = true;
  emit('uploadImage', formData, index, parentIndex);
};

const closeModal = () => {
  emit('close');
};

const handleFetchNextPage = () => {
  emit('fetchNextPage');
};

const saveModal = () => {
  if (props.optionType === 'color') {
    isColorNotSet.value = dataClone.value[props.index].optionValues.some((op) => {
      if (!op.colors?.length && !op.other) return true;
    });
  } else if (props.optionType === 'image') {
    isImageNotSet.value = dataClone.value[props.index].optionValues.some((op) => {
      if (!op.imageUrl?.length && !op.other) return true;
    });
  }

  if (!saveClicked.value && (props.optionType === 'color' ? isColorNotSet.value : isImageNotSet.value)) {
    saveClicked.value = true;
    return;
  }
  emit('save');
};

const handleAcceptWarning = () => {
  isImageNotSet.value = false;
};

const handleChangeData = (dataChange: GlobalSwatchesData[]) => {
  const optionOriginal = dataOriginal[props.index].optionValues;
  const refOptionChange = dataChange[props.index].optionValues;
  isDataDiff.value = JSON.stringify(refOptionChange) !== JSON.stringify(optionOriginal);
  emit('changeData', dataChange);
};

const alertError = (msg: string) => {
  emit('handleAlert', 'error', msg, 3000);
};

watch(
  () => props.data,
  () => {
    dataClone.value = props.data;
  },
);
</script>

<template>
  <g-modal
    :is-open="true"
    :show-btn-close="true"
    :btn-ok-disable="!isDataDiff || imageUploading"
    :click-out-side-close="false"
    header-class="cursor-default"
    label-ok="Apply for all products"
    container-class="h-[680px] max-h-[calc(100vh_-_72px)]"
    body-class="h-full"
    @cancel="closeModal"
    @close="closeModal"
    @ok="saveModal">
    <template #title>
      <div class="font-semibold">{{ t('Configure variants style') }}</div>
    </template>
    <template #default>
      <div class="w-[900px]">
        <g-collapse-transition v-if="!isLoading" :duration="200">
          <component
            :is="handleInitComponent(optionType)"
            :option-title="optionTitle"
            :data="data"
            :index="index"
            :images="images"
            :shopify-plan="shopifyPlan"
            :shop-id="shopId"
            :maximum-size="maximumSize"
            :allowed-files="allowedFiles"
            :save-clicked="saveClicked"
            :is-data-diff="isDataDiff"
            :is-color-not-set="isColorNotSet"
            :is-image-not-set="isImageNotSet"
            :is-instant="isInstant"
            :show-other="showOther"
            @fetch-next-page="handleFetchNextPage"
            @accept-warning="handleAcceptWarning"
            @handle-alert="alertError"
            @upload-image="handleUploadImage"
            @change-data="handleChangeData" />
        </g-collapse-transition>
        <SkeletonManagerSwatches v-else />
      </div>
    </template>
  </g-modal>
</template>
